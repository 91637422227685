import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import CommonSearch from "../../../components/commonSearch/CommonSearch";
import globalSearchService from "../../../components/commonSearch/globalSearchServices";
import directoryServices from "../directoryServices";


export default function Management(param: any) {

    const [showtab, setShowTab] = useState(false);
    let [activeMenu, setActiveMenu] = useState("Management");
    let [activeCategory, setActiveCategory] = useState("All");
    let [viewAllList, setviewAllList] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    const [perCategory, setperCategory] = useState(false);
    let [totalRow, settotalRow] = useState(0);
    const [detailView, setdetailView] = useState(false);


    const [activeTab, setActiveTab] = useState('Fraudulent');
   
    const handleTabClick = (tab : any) => {
      setActiveTab(tab);
    };

    var loop = 0;
    var pagination = {
        'pageSize': 10,
        'pageNumber': 1,
        "display": "Category",
        "type": "Management"
    }
    useEffect(() => {

        globalSearchService.lookUpTypeList().then((res) => {
            global.lookUpTypeList = res;
        });

        setActiveCategory("All");
        if (loop == 0) {
           
            console.log(loop)
            loop = loop + 1;
        }

    }, [param])


    const SearchForHandle = (e: any, sts: any) => {
        console.log(e)
        console.log(sts)

        if ((sts == true)) {

            directoryServices.managementListing({ ...e, ...pagination }).then((res) => {
                console.log(res)


                res.map((values: any) => {

                    if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            }) /*  */
        }
        setperCategory(false);
    }


    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }


return(

  <div className="landing ws-12">

        <div className="ws-12">

            <div className="box-header-main">
                <h2>Music Directories</h2>
            </div>

            <div className="ws-2 box mtxl pm">
                    <ul className="main-menu">
                        <a className="menu-header">Music Directories</a>
                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }
                        </ul>
                        <a className="menu-header">Data Directories</a>

                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </ul>

                    <div className="main-menu-span m0 box ws-12 fullHeight hide">
                        <div className="sidebar-nav ws-12">
                            <ul className="nav nav-stacked main-menu m0 ws-12" id="mainMenu">
                                <li key="MusicDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Music Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>

                                <li key="DataDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Data Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>


        

        <div className="ws-10 pull-right pm" >
             {(detailView == false) && <>
                
                <div className="ws-9">
                    <ul className="nav nav-tabs p0 m0 "> 
                        <li className={`m0 mt mr borderNone roundedButtons pll prl ${activeTab === 'Perfomance' ? 'active' : ''}`} onClick={() => handleTabClick('Perfomance')}>Perfomance</li>
                        <li className={`m0 mt mr borderNone roundedButtons pll prl ${activeTab === 'Royalties' ? 'active' : ''}`} onClick={() => handleTabClick('Royalties')}>Royalties</li>
                        <li className={`m0 mt mr borderNone roundedButtons pll prl ${activeTab === 'Fraudulent' ? 'active' : ''}`} onClick={() => handleTabClick('Fraudulent')}>Fraudulent</li>
                    </ul>
                </div>
             
             <hr/>
                <div className="ws-12 ">
                    <CommonSearch SearchForHandle={SearchForHandle} type="contractGrid" title="Management" width="ws-10 pull-left"/>
                </div>



             <div className="ws-12">
                <div className="box-content">
                    <h2>Showing <span className="info">{totalRow}</span>Results</h2>
                </div>
             </div>

            <div className="ptl"> 
                <div className="ws-12 mtl">
                    <span className="alert alert-success ws-12 text-center">No data Available</span>
                </div>

                <div className="ws-12">
                    <button className="btn-success pull-right">Export</button>
                </div>

            </div>
             </>}
        </div>

        </div>


  </div>


)

}