import Creator from "../../components/directories/creator";
import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Imprint from "./imprint";
import Accounts from "./Accounts";
import AssetDirectory from "./assetDirectory";
import Header from "../Header/header";
import Management from "../directories/Uploader/Management";


export default function DirectoriesIndex(subEntity: any) {

    const [showtab, setShowTab] = useState(false);
    const [loop, setloop] = useState(1);
    let [activeMenu, setActiveMenu] = useState("Creators");
    const onChangeMenu = (e: any) => {
        console.log(e)
        setActiveMenu(e)
    }

    return (
        <>

            {(subEntity && subEntity.subEntity && subEntity.subEntity.subEntityCode) && <div className="pull-left">
                <div className="box-primary text-center iconLogo pull-left">
                    <div className="ws-12" onClick={() => { setShowTab(true); }}>
                        <i className={'box icon-' + subEntity.subEntity.subEntityCode} />
                        <span className="m0 pts" >{subEntity.subEntity.subEntity} </span>
                    </div>
                </div>

                <Modal open={showtab} onClose={() => setShowTab(false)} center >

                    <div className="ws-12" >

                        {(activeMenu == 'Creators') && <Creator onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                        {(activeMenu == 'Imprints') && <Imprint onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                        {(activeMenu == 'Assets') && <AssetDirectory onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                        {(activeMenu == 'Management') && <Management onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                        {(activeMenu == 'Accounts') && <Accounts onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}

                    </div>

                </Modal>
            </div>}



            {(subEntity && !subEntity.subEntity) && <div>

                <div className="pull-left ws-12">

                    <div className='ws-12 bgWhite'>
                        <Header  />
                    </div>
                                        
                    {(activeMenu == 'Creators') && <Creator onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                    {(activeMenu == 'Imprints') && <Imprint onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                    {(activeMenu == 'Assets') && <AssetDirectory onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                    {(activeMenu == 'Management') && <Management onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}
                    {(activeMenu == 'Accounts') && <Accounts onChangeMenu={onChangeMenu} activeMenu={activeMenu} />}

                </div>
            </div>}
        </>

    )
}
