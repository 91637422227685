import Tooltip from "rc-tooltip";
import React, { Suspense, useState, useEffect } from "react"; 
import { CommonUtilities } from "../../commonUtils";

export default function GenericScraping({ onSelectItem }: any) { 
    const [requestJSON, setRequestJSON] = useState('');
    const [resourceResponse, setResourceResponse] = useState<any[]>([]); 

    useEffect(() => {

 
    }, []);
  
    async function searchData() {
        /*const url = "https://master.dws03r4d6820k.amplifyapp.com/api/assets/";*/
 
        const payload = {
            method: 'GET',
            headers: {
                'x-api-key'         : "25520aaf-e317-4794-822f-8aae77b48c28",
                'Accept'            : '*/*', 
                "X-RapidAPI-Key"    : "25520aaf-e317-4794-822f-8aae77b48c28",
                'Content-Type'      : "application/json; charset=utf-8",
            },
        }; 


        fetch('https://master.dws03r4d6820k.amplifyapp.com/api/assets/', {
            method: 'GET', // Or 'POST', 'PUT', etc.
            headers: {
                "x-api-key"         : "25520aaf-e317-4794-822f-8aae77b48c28",
                "Accept"            : "application/json",
                "Authorization"     : "Bearer 25520aaf-e317-4794-822f-8aae77b48c28",
                "X-Amz-Cf-Id"       : "BUg1gHk7-TwOGy58PQ77ldluuhI4kibP9ryVPu4WYcTZB8cvRRh8SQ==",
                'Referrer': 'Access-Control-Allow-Origin' ,
            },
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));


        //const data = await fetch("https://master.dws03r4d6820k.amplifyapp.com/api/assets/", payload).then((response) => response.json());
        //console.log(data)
    }


    const handleLinkButtonClick = (item: any) => {

        onSelectItem(item);
    };

 
    return (
        <div className="pull-left ws-12 full-width">
            <div className="box">
                <div className="box-header">Spotify</div>
                <div className="box-content mtl ptl"> 
                    <input type="text" className="ws-12 mtll" value={requestJSON} onChange={(e) => setRequestJSON(e.target.value)} />
                </div>

                {resourceResponse && resourceResponse.length > 0 && (
                    <div>
                        {resourceResponse.map((item, index) => (
                            <div className="creatorImg pm pull-left" key={index}>

                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                    <div className="coverPicture">
                                        <div className="labelOverlay mt">

                                            <i  onClick={() => handleLinkButtonClick(item)} className="icon-LinkMade text-m white text-Shadow ml"></i>
                                            
                                        </div>

                                        <Tooltip placement="right" overlayStyle={{ width: '400px', height: '50px' }} trigger={['hover']} overlay={
                                            <div className='box'>
                                                <div className='box-header'>
                                                    Track Details
                                                </div>
                                                <div className='box-content'>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Track Name:</span> {item.name}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>ISRC:</span>{item.external_ids.isrc}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Artist Name:</span>{item.artists[0].name}
                                                    </div>

                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Type:</span>{item.type}
                                                    </div>

                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Popularity:</span>{item.popularity}
                                                    </div>

                                                </div>
                                            </div>}>


                                            <i className="icon-info1 pull-right positionAbsolute rightReset mr mt text-m white text-Shadow"> </i>

                                        </Tooltip>

                                        <img src={item.album.images[0].url} alt={item.name} />
                                        <div className="bottomOverlayLabel">
                                            <label className="control-label ws-12">{CommonUtilities.longCharacter(item.name, 18)}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                        
                        {/* <DspPagination onChange={handlePageChange} totalRows={totalItems}/> */}
                    </div> 
                )}

                <div className="box-footer">
                    <button className="btn-success" onClick={() => searchData()}>
                        Request
                    </button>

                    
                   
                    
                </div>
            </div>
        </div>
    );
}