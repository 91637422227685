import React, { Suspense, useEffect, useState } from "react";
import { CommonUtilities } from '../../commonUtils';

export default function Pagination({ onChange, totalRows }: any) {

    const [value, setValue] = useState(10);
    var [currentPageNumber, setcurrentPage] = useState(1);
    var counter = 0;
    const [active_buttton, setactive_buttton] = useState({ btn1: true, btn2: false, btn3: false, btn4: false, btn5: false, btn6: false });
    let [arrayButton, setarrayButton] = useState({ bt1: "...", bt2: "1", bt3: "2", bt4: "3", bt5: "...", bt6: "Next" });
    let [arrayButtonStatus, setarrayButtonStatus] = useState({ btn1: false, btn2: true, btn3: true, btn4: true, btn5: true, btn6: true, btn7: true });

    useEffect(() => {
        if (totalRows <= 10) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: false, btn6: true, btn7: false
            }))

        } else if (totalRows >= 50) {
            setactive_buttton(existingValues => ({
                ...existingValues, btn1: false, btn2: true, btn3: false, btn4: false, btn5: false, btn6: false
            }))
        }
        var divison = (totalRows / (PramObject.pageSize));
        if (counter == 0) { for (let i = 0; divison > i; i++) { counter++; } }

        //maxPageNum(1)  
        buttonDisplay((counter))
    }, []);

    let PramObject = {
        pageSize: value,
        pageNumber: currentPageNumber,
        totalRow: totalRows
    }

    const currentPagination = (e: any) => {

        if (e != '...') {
            maxPageNum(3)
            let num = +e;

            if (counter <= 5) {
                var mod = num % counter;
                if (mod == 0) {
                    mod = counter;
                }
                if (mod == 5) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: false, btn4: false, btn5: true, btn6: true
                    }))
                } else if (mod == 2) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: true, btn3: false, btn4: false, btn5: false, btn6: true
                    }))
                } else if (mod == 1) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: true, btn2: false, btn3: false, btn4: false, btn5: false, btn6: true
                    }))
                } else if (mod == 3) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: true, btn4: false, btn5: false, btn6: true
                    }))
                } else if (mod == 4) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: false, btn4: true, btn5: false, btn6: true
                    }))
                } else {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: false, btn4: false, btn5: false, btn6: true
                    }))
                }

            } else {
                var mod = Number(num) % 3;
                if (mod == 0) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: false, btn4: true, btn5: false, btn6: false
                    }))
                } else if (mod == 2) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: true, btn4: false, btn5: false, btn6: false
                    }))
                } else if (mod == 1) {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: true, btn3: false, btn4: false, btn5: false, btn6: false
                    }))
                } else {
                    setactive_buttton(existingValues => ({
                        ...existingValues, btn1: false, btn2: false, btn3: false, btn4: false, btn5: false, btn6: false
                    }))
                }
            }

            if (currentPageNumber = (counter - 1)) {
                var regulate = counter % 3;

                if (regulate == 0) { }
                /*
                else if((regulate = 1) && (counter > 3)) {buttonDisplay(-1); }
                else if((regulate = 2) && (counter > 3)) {buttonDisplay(-2); }
                else if((counter = 2)  && (counter < 3)) {buttonDisplay(-2); }
                else if((counter = 1)  && (counter < 3)) {buttonDisplay(-2); }*/
            }
            if (e != '...') {
                setcurrentPage(currentPageNumber = e);
                handleChangeButt(1);

            }
            if (e == 1) { maxPageNum(3); buttonDisplay(counter) }
        }

    }

    const ButtonVisibility = (e: any) => {
        if (e == 0) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: false, btn6: true
            }))
            setarrayButton(btnValues => ({
                ...btnValues, bt2: (counter - 2).toString(), bt3: (counter - 1).toString(), bt4: (counter).toString()
            }))
        } else if (e == 1) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: true
            }))
        } else if (e == 2) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: false
            }))
        } else if (e == 3) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: true, btn6: true
            }))
        } else if ((currentPageNumber == counter)) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn6: false
            }))
            //reset button default
        } else if (e == 4) {
            setarrayButtonStatus(existingValues => ({
                ...existingValues, btn1: false, btn2: true, btn3: true, btn4: true, btn5: true, btn6: true
            }))
        }
    }

    const buttonDisplay = (ErrorNumber: any) => {
        switch (ErrorNumber) {

            case -3:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: false, btn6: false
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt2: (counter).toString(), bt3: (counter + 1).toString(), bt4: (counter + 2).toString()
                }))
                break;

            case -2:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: true, btn4: false, btn5: false, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt2: (counter - 1).toString(), bt3: (counter).toString(), bt4: (counter + 1).toString()
                }))
                break;

            case -1:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: false, btn4: false, btn5: false, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt2: (counter).toString(), bt3: (counter + 1).toString(), bt4: (counter + 2).toString()
                }))
                break;

            case 5:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: true, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: (1).toString(), bt2: (2).toString(), bt3: (3).toString(), bt4: (4).toString(), bt5: (5).toString()
                }))
                break;

            case 4:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: true, btn4: true, btn5: false, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: (1).toString(), bt2: (2).toString(), bt3: (3).toString(), bt4: (4).toString()
                }))
                break;

            case 3:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: true, btn4: false, btn5: false, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: (1).toString(), bt2: (2).toString(), bt3: (3).toString()
                }))
                break;

            case 2:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: true, btn3: false, btn4: false, btn5: false, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: (1).toString(), bt2: (2).toString()
                }))
                break;

            case 1:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: true, btn2: false, btn3: false, btn4: false, btn5: false, btn6: false
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: (1).toString()
                }))
                break;

            default:
                setarrayButtonStatus(existingValues => ({
                    ...existingValues, btn1: false, btn2: true, btn3: true, btn4: true, btn5: true, btn6: true
                }))
                setarrayButton(btnValues => ({
                    ...btnValues, bt1: "...", bt2: "1", bt3: "2", bt4: "3", bt5: "...", bt6: "Next"
                }))
                break;
        }
    }

    const maxPageNum = (e: any) => {
        var divison = (PramObject.totalRow / (PramObject.pageSize));
        if (counter == 0) { for (let i = 0; divison > i; i++) { counter++; } }

        if (e == 0) {
            if (counter == 0) {
                currentPagination(1)
            } else if (counter > 0) {
                //currentPagination(1) 
                currentPagination(counter)
                ButtonVisibility(0);
            }

            if (counter > 5) {
                var regulate = counter % 3;
                if (regulate == 0) {
                } else if (regulate = 1) {
                    buttonDisplay(-1);
                } else if (regulate = 2) { buttonDisplay(-2); }

            } else { buttonDisplay(counter); }
        } else if (e == 1) {
            currentPagination(1)
        }
    }

    //set of value to show
    const incremental = (Status: number) => {
        var divison = (PramObject.totalRow / (PramObject.pageSize));
        if (counter == 0) { for (let i = 0; divison > i; i++) { counter++; } }

        if (Status == 0 && arrayButton.bt1 == '...') {

            currentPagination(Number(parseInt(arrayButton.bt4) - 3))
            ButtonVisibility(3);

            setarrayButton(btnValues => ({
                ...btnValues,
                bt2: (parseInt(arrayButton.bt2) - 3).toString(), bt3: (parseInt(arrayButton.bt3) - 3).toString(), bt4: (parseInt(arrayButton.bt4) - 3).toString()
            }))

            if (arrayButton.bt2 == "4") {
                ButtonVisibility(2)
            }
            /* setactive_buttton( existingValues  => ({
                 ...existingValues,btn1:false , btn2:false, btn3:false , btn4:true , btn5:false , btn6:false}))*/
        } else if (Status == 1 && arrayButton.bt5 == '...') {

            if ((Number(arrayButton.bt4) + 3) <= counter) {


                setarrayButton(btnValues => ({
                    ...btnValues,
                    bt2: (parseInt(arrayButton.bt2) + 3).toString(), bt3: (parseInt(arrayButton.bt3) + 3).toString(), bt4: (parseInt(arrayButton.bt4) + 3).toString()
                }))
                currentPagination(Number(parseInt(arrayButton.bt2) + 3))
                ButtonVisibility(1)
                /*setactive_buttton( existingValues  => ({
                    ...existingValues,btn1:false , btn2:true, btn3:false , btn4:false , btn5:false , btn6:false}))*/
                // currentPagination();
            }
        } else if (Status == 2) {
            setarrayButton(btnValues => ({
                ...btnValues, bt2: "1", bt3: "2", bt4: "3"
            }))
            ButtonVisibility(2)
        }
    }

    //next and previous button
    const buttonAction = (input: any) => {
        maxPageNum(3);

        if (input == 1 && (currentPageNumber <= (counter - 1))) {
            currentPageNumber = currentPageNumber + 1
            setcurrentPage(Number(currentPageNumber) + 1);
            currentPagination(currentPageNumber)
            handleChangeButt(1);

            if (Number(currentPageNumber) == parseInt(arrayButton.bt2) + 3) { incremental(1); }

        } else if (input == -1) {
            if (currentPageNumber != 1) {
                setcurrentPage(currentPageNumber = (Number(currentPageNumber) - 1));
                currentPagination(currentPageNumber)
                handleChangeButt(1);
            }

            if (Number(currentPageNumber) == parseInt(arrayButton.bt2) - 1) { incremental(0); }
        }
    }

    const handleChangeButt = (e: any) => {
        if (e != 1) {
            setValue(e.target.value);
            PramObject.pageSize = e.target.value;
            PramObject.pageNumber = 1;
            currentPageNumber = 1;
            maxPageNum(3);
            buttonDisplay(counter)
        }
        PramObject.pageNumber = currentPageNumber;
        onChange(PramObject);
    }

    var options = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
    ];

    return (
        <div className="pull-left pagination">
            <Suspense fallback={<div>Loading</div>}>

                <ul >
                    <li onClick={() => { currentPagination(1) }}>First</li>
                    <li onClick={() => { buttonAction(-1) }}>Prev</li>

                    {arrayButtonStatus.btn1 && <li className={(active_buttton.btn1) ? 'active' : ''} onClick={() => { incremental(0); currentPagination(arrayButton.bt1) }}> <span>{arrayButton.bt1}</span></li>}

                    {arrayButtonStatus.btn2 && <li className={(active_buttton.btn2) ? 'active' : ''} onClick={() => { currentPagination(arrayButton.bt2) }}> {arrayButton.bt2} </li>}
                    {arrayButtonStatus.btn3 && <li className={(active_buttton.btn3) ? 'active' : ''} onClick={() => { currentPagination(arrayButton.bt3) }}> {arrayButton.bt3} </li>}
                    {arrayButtonStatus.btn4 && <li className={(active_buttton.btn4) ? 'active' : ''} onClick={() => { currentPagination(arrayButton.bt4) }}> {arrayButton.bt4} </li>}

                    {arrayButtonStatus.btn5 && <li className={(active_buttton.btn5) ? 'active' : ''} onClick={() => { incremental(1); currentPagination(arrayButton.bt5) }}> {arrayButton.bt5}</li>}

                    {arrayButtonStatus.btn6 && <li onClick={() => { buttonAction(1) }}>{arrayButton.bt6}</li>}
                    <li onClick={() => maxPageNum(0)}>Last </li>

                    {arrayButtonStatus.btn7 && <select className="ml pb mt pt" value={value} onChange={handleChangeButt}>
                        {options.map((option) => (
                            <option value={option.value}>{option.label}</option>

                        ))}
                    </select>}

                </ul>

            </Suspense>
        </div>
    )
}