import { CommonUtilities }  from '../../commonUtils';

export default class ReportsServices  {

    static globalSearch(param: any) {
        var url = 'metadata/globalSearch/list?'+ CommonUtilities.buildQueryString(param, true, true);
        return CommonUtilities.getData(url);
    }




    static ReportMenu() {
        var url = 'reports/type9?isReportMenu=true';
        return CommonUtilities.getData(url);
    }

    static ExistingReferences() {
        var url = 'contract/processReference/reporting?pageNumber=1&pageSize=10&';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions(data : any) {
        var url = 'contract/processReference/reporting/invoices?processReferenceId=5&manualReference=Culture001-20200630-463316&isActive=1&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page1() {
        var url = 'contract/processReference/reporting/invoices?&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page2() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=2&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page3() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=3&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page4() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=4&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page5() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=5&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page6() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=6&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page7() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=7&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page8() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=8&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page9() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=9&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page10() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=10&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page11() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=11&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AddedTransactions2Page12() {
        var url = 'contract/processReference/reporting/invoices?sortBy=status&sortOrder=ASC&pageNumber=12&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static periodCount() {
        var url = 'common/periodCount';
        return CommonUtilities.getData(url);
    }

    static generate() {
        var url = 'affiliate/reportingPack/generate?periodNum=20240331';
        return CommonUtilities.getData(url);
    }

    static saveReport() {
        var url = 'reports/saveReport';
        return CommonUtilities.getData(url);
    }

    static exportReport() {
        var url = 'affiliate/royaltyanalysis/Export?type=014&periodNum=20240331';
        return CommonUtilities.getData(url);
    }

    static generateArchive() {
        var url = 'reportingPack/generateArchive?ReportingClass=Royalty%20Expense&ReportingArea=Recorded%20Music&op=Summary&ReportingType=Summary&pageNumber=1&pageSize=10&manualReference=Culture001-20200630-463316&mode=Summary';
        return CommonUtilities.getData(url);
    }

}