import React, { useEffect, useState } from "react";
import ReportsServices from './ReportsServices';
import { CommonUtilities }  from '../../commonUtils';
import Modal from "react-responsive-modal";

    export default function CustomReports_Template  () {
  
        useEffect(() => {
            ReportsServices.ReportMenu()
                .then((res) => {
                    console.log(res)
    
                    
                    setReportMenu(res);
                    
                    })
        }, []);


        const getGenerate = () => {

            ReportsServices.generate()
                .then((res) => {
                    console.log(res)
    
                    console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                    setgenerate(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
    
                     });
                    
        }

        useEffect(() => {
            ReportsServices.periodCount()
                 .then((res) => {
                     console.log(res)
     
                     
                     setperiodCount(res);
                      
                     })
         }, []);

        const [generate, setgenerate] = useState<any[]>([]);
        const [ReportMenu, setReportMenu] = useState<any[]>([]);
        const [periodCount, setperiodCount] = useState<any[]>([]);
        const [openFirst, setOpenFirst] = React.useState(false);
        
    
    return(
    
        <div className="ws-12">
        
        
            <div className="box-header">
                <h2 className="ws-2">Custom Reports</h2>
                                
                <div className="form-format ws-10">

                    <button className="btn btn-primary btn-sm pull-right" onClick={() => { getGenerate(); }}><i className="fa fa-save"></i>Generate</button>
                    <select className="form-control pull-right">
                        {
                            periodCount.map((post, index) => {
                                return (
                                    <option value="1" >{ CommonUtilities.dateNameFilter(post.periodFrom)}- { CommonUtilities.dateNameFilter(post.periodTo)}</option> 
                        )})}
                    </select>

                    <label className="ws-1 mt pull-right"> Select Period</label>

                </div>

                                                                
            </div>
        
            <div className="ws-4 ">
                <div className="box-primary ms text-center iconLogo pull-left mll mrl" onClick={() => { setOpenFirst(true)}}>
                    <i className="box icon-reporting1 pbl"/>
                    <span className="ws-12 mt"> 040</span>
                </div>
                <span className="mtl pts text-bold ws-8 ">040 Master Report</span>

            </div>

            <div className="ws-4 ">
                <div className="box-primary ms text-center iconLogo pull-left mll mrl" onClick={() => { setOpenFirst(true)}}>
                    <i className="box icon-reporting1 pbl"/>
                    <span className="ws-12 mt"> 050</span>
                </div>
                <span className="mtl pts text-bold ws-8 ">050 Royalty Summary</span>

            </div>

                    <Modal open={openFirst} onClose={() => setOpenFirst(false)} center >
                        <div className="box-header-main">
                            <h2><i className="fa fa-clone"></i>Export Options</h2>
                        </div>

                        <div className="box-content">
                            <form className="form-horizontal form-condensed" name="frmexport">
                                <div className="row-fluid">
                                    <div className="control-group m0 mb">

                                        <div className="ws-12 m0 mb">

                                            <label className="ws-2 control-label mrl prl mtl text-bold">Format:</label>

                                            <div className="ws-1 mtl">
                                                <label className="ml">xlsx</label>
                                                <input type="radio" value={'xlsx'} className="m0"/>
                                            </div>

                                            <div className="ws-1 mtl">
                                                <label className="ml">csv</label>
                                                <input type="radio" value={'csv'} className="m0"/>
                                            </div>

                                        </div>

                                        <div className="ws-12 m0 mb">
                                            <label className="ws-2 control-label mrl prl mtl text-bold">Number of rows:</label>
                                            <div className="ws-5 mts">
                                                <input type="number" name="numRows" min={100} max={1048575}/>
                                            </div>
                                        </div>

                                        <div className="ws-12 m0">
                                            <label className="ws-2 control-label mrl prl mtl text-bold">File Name:</label>
                                            <div className="ws-5 mts">
                                                <input type="text" name="exportPopupOptionsParams.fileName" className="ws-12"/>
                                            </div>
                                        </div>

                                        <div className="ws-12 m0">
                                            <label className="ws-6 control-label danger mrl prl mtl text-bold">Number of rows must be between 100 - 1048575</label>
                                        </div>


                                    </div>
                                </div>
                            </form>

                        </div>

                        <div className="box-footer">
                            <button className="btn-info">Export</button>
                        </div>

                    </Modal>

                    

                                                                                
    </div>
    
    
    )
                   
}