import React, { useEffect, useState } from "react";
import ReportsServices from './ReportsServices';
import { CommonUtilities }  from '../../commonUtils';
import RecordedMusic_Template from "./RecordedMusic_Template";
import Mechanical_Template from "./Mechanical_Template";
import Publishing_Template from "./Publishing_Template";

export default function AccountingRecognition_Template () {
       
    const tabs = [
        {
            id: 1, keys: "Recorded Music",  title: "Recorded Music"
        },
        {
            id: 2, keys: "Mechanical",title: "Mechanical"
        },
        {
            id: 3,keys: "Publishing", title: "Publishing"
        },
        
        
    ]

    const [displaytabs , setDisplayTabs] = useState(1);
    const currentPage = (e: React.SetStateAction<number>) => {
        setDisplayTabs(e);
        return 1;
     } 


return(

   
        

        <div className="ws-12">

                    

                    <div className="ws-12 pm" style={{backgroundColor : '#f6f8ff ' }}>

                        <ul className="nav nav-tabs m0 p0">
                            {tabs.map((o) => {
                                var temp = '#/Reports/';
                                return <li className ={displaytabs === o.id? "btn active": "btn"} onClick= {() => currentPage(o.id)} > {o.title}</li>
                                    
                            })}                                    
                        </ul>
                            
                    </div>


                    <div className="pLarge">
                    {(() => {
                        switch (displaytabs) {
                            case 1 : return  <RecordedMusic_Template/>
                            case 2 : return  <Mechanical_Template/>
                            case 3 : return  <Publishing_Template/>                           
                        }
                    })()}
                    </div>


                </div>

    

)

}