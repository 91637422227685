import { SelectContact } from '../../../components/Contacts/selectContact';
import Pagination from '../../../components/Header/Pagination';
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from '../directoryServices';
import Drawer from 'react-modern-drawer';

export default function TrackSlider(param: any) {

    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    let [SubClassificationtotalRow, setSubClassificationtotalRow] = useState(1);
    let [listSubClassification, setlistSubClassification] = useState<any>([]);
    let [SubClassificationSearch, setSubClassificationSearch] = useState("");
    let [ClassificationtotalRow, setClassificationtotalRow] = useState(1);
    let [listClassification, setlistClassification] = useState<any>([]);
    let [ClassificationSearch, setClassificationSearch] = useState("");
    let [imageUpload, setImageUpload] = useState<any>([]);
    let [listImprint, setlistImprint] = useState<any>([]);
    let [EditValue, setEditValue] = useState<any>([]);
    let [saveStatus, setSaveStatus] = useState(false);
    let [slider, setslider] = useState(false);

    let classFicationPayload = {
        formArea: "Tracks",
        pageNumber: 1,
        pagesize: 10,
        useFreeText: ""
    }

    var pickerColors = [

        '#EF534F', '#C62928', '#980A0B',

        '#BA69C8', '#7B1FA2', '#4A148C',

        '#F57C02', '#E65100',

        '#81C784', '#2F7D31', '#1A5E20',

        '#7FDEEA', '#00838F', '#004D41',

        '#90CAF9', '#029BE5', '#01579B',

        '#DBDBDB', '#000000'

    ];

    var SubclassFicationPayload = {
        area: "Tracks",
        referenceCode: "",
        text: "",
        pageNumber: 1,
        pagesize: 10
    }

    const closePage = () => {
        EditValue = [];
        setEditValue(EditValue);
        param.onAddClose("close");
    }

    useEffect(() => {
        directoryServices.imprintListing().then((res) => {
            setlistImprint(res)
        })

        console.log(param.editData)
        if (param.editData && param.editData.assetCode) {

            console.log(EditValue)
            directoryServices.metadataTrackListing({ isrc: param.editData.assetCode }).then((values) => {
                console.log(values)
                setEditValue({ ...values[0], artistName: param.editData.assetContactName, artist: param.editData.assetContactName, image: param.editData.assetImageTH });
            })

            setSaveStatus(true)
            directoryServices.musicDirectoryCategoryListing({ code: param.editData.assetCode, CategoryType: "Tracks" }).then((res) => {
                setassociatedclassification(res)
            })

            SubclassFicationPayload.referenceCode = param.editData.assetCode;

            directoryServices.tagListing(SubclassFicationPayload).then((res) => {
                setassociatedSubclassification(res)
            })
        }

    }, [param.open]);

    const ClassificationListing = () => {
        if (ClassificationSearch.length > 1) {
            classFicationPayload.useFreeText = ClassificationSearch;
        }
        directoryServices.zFreeTextListing(classFicationPayload).then((res) => {
            setlistClassification(res)
            setClassificationtotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    const handleNameOnChange = (n: any) => {
        classFicationPayload.pageNumber = n.pageNumber;
        classFicationPayload.pagesize = n.pageSize;
        ClassificationListing()
    }

    const handleNameOnChangeSub = (n: any) => {
        SubclassFicationPayload.pageNumber = n.pageNumber;
        SubclassFicationPayload.pagesize = n.pageSize;
        SubClassificationListing()
    }

    const ClassificationSave = (e: any) => {

        if (e != false) {

            directoryServices.musicDirectoryCategorySave({ Code: " ", categoryName: e, categoryType: "Tracks" });
        } else {
            directoryServices.zFreeTextsave({ formArea: "Tracks", useFreeText: ClassificationSearch });
            directoryServices.musicDirectoryCategorySave({ Code: " ", categoryName: ClassificationSearch, categoryType: "Tracks" });
        }

    }

    const SubClassificationListing = () => {

        if (SubClassificationSearch.length > 1) {
            SubclassFicationPayload.text = SubClassificationSearch;
        }
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setlistSubClassification(res)
            setSubClassificationtotalRow(res[0] ? res[0].totalRows : 0)
        })

    }

    const SubClassificationSave = (e: any) => {

        if (e != false) {
            directoryServices.musicDirectoryCategorySave({ Code: " ", categoryName: e, categoryType: "Tracks" });
        } else {
            directoryServices.tagSave({ area: "Tracks", code: SubClassificationSearch, id: 0, isActive: 1, isCustom: 1, referenceCode: " ", text: SubClassificationSearch });
            //directoryServices.musicDirectoryCategorySave({Code:" " , categoryName:ClassificationSearch,categoryType:"Tracks"} );
        }

    }

    const deleteSubTaging = (e: any) => {
        directoryServices.tagdelete(e).then((res) => {
            SubclassFicationPayload.referenceCode = " ";
            directoryServices.tagListing(SubclassFicationPayload).then((res) => {
                setassociatedSubclassification(res)
            })

        })
    }

    const deleteTaging = (e: any) => {
        directoryServices.musicDirectoryCategoryDelete(e).then((res) => {

            directoryServices.musicDirectoryCategoryListing({ code: EditValue.intStdRecCode, CategoryType: "Tracks" }).then((res) => {
                setassociatedclassification(res)
            })
        })
    }

    const onChangeContact = (e: any, error: any) => {

        console.log(e)
        if (e) {
            setEditValue({ ...EditValue, artist: e.data.kontactCode, artistName: e.data.kontactName });
            console.log(EditValue)
        }

        if (error == 'close') {
            setslider(false)
        }
    }

    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;

        console.log(field_name)
        if (field_name == "label") {
            //listImprint


            var value = (listImprint.filter((data: any) => (data.companyName == field_value))[0])
            console.log(value)
            setEditValue({ ...EditValue, ...{ [field_name]: value } });
        } else {
            setEditValue({ ...EditValue, ...{ [field_name]: field_value } });
        }


    }

    const handleFileChange = (e: any) => {

        var fileName = e.target.files[0];
        if (e.target.files) {
            setImageUpload(fileName)
            fileName = URL.createObjectURL(fileName)
            setEditValue({ ...EditValue, ...{ image: fileName } });
        }
    }

    const SaveAsset = () => {

        console.log(EditValue)
        setSaveStatus(true)
        directoryServices.trackSave(EditValue);

        if (imageUpload) {
            directoryServices.uploadTrackImage(imageUpload, { "assetCode": EditValue.intStdRecCode, "type": "Tracks" }).then((values: any) => {
                console.log(values)

                directoryServices.MainAssetsave({
                    AssetCode: EditValue.intStdRecCode,
                    AssetContactCode: EditValue.artist, AssetContactName: EditValue.artistName,
                    AssetImage: values && values.actual ? values.actual : '', AssetImageTH: values && values.thumbnail ? values.thumbnail : '',
                    AssetImprint: EditValue.label.companyName, AssetTitle: EditValue.trackName, AssetType: "T"
                });
            })
        } else {
            directoryServices.MainAssetsave({
                AssetCode: EditValue.intStdRecCode,
                AssetContactCode: EditValue.artist, AssetContactName: EditValue.artistName,
                AssetImprint: EditValue.label.companyName, AssetTitle: EditValue.trackName, AssetType: "T"
            });
        }


        directoryServices.musicDirectoryCategoryListing({ code: EditValue.intStdRecCode, CategoryType: "Tracks" }).then((res) => {
            setassociatedclassification(res)
        })
        SubclassFicationPayload.referenceCode = EditValue.intStdRecCode;
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

    }

    const generateISRC = () => {
        console.log(EditValue)
        directoryServices.generateItemISRC(EditValue).then((res) => {
            setEditValue(res)
        })
    }


    return (<>
        <Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%" }}>

            <div className="ws-6 box pull-right box">
                <div className='box-header-main'>
                    <h2 className="pull-left ws-10"><i className="fa fa-sticky-note"></i> New Track</h2>
                </div>

                <div className='box-content'>
                    <div className="ws-12">

                        <div className="ws-10 form-format m0 mb">
                            <label className="control-label">Imprint*</label>
                            <div className="label-entries ws-12">

                                <><select key="label" name="label" value={(EditValue && EditValue.label && EditValue.label.companyName) ? EditValue.label.companyName : ""} onChange={EditHandler}>
                                    <option value="" > Choose an Imprint</option>

                                    {
                                        listImprint?.map(((values: any) => {
                                            return (
                                                <option value={values.companyName} label={values.companyName}>{values.companyName}</option>
                                            )
                                        }))
                                    }

                                </select></>

                            </div>
                        </div>

                        <div className="ws-10 form-format mb">
                            <label className="control-label" >Code*</label>
                            <div className="label-entries ws-12">
                                <div className="ws-9">
                                    <input key="intStdRecCode" type="text" className="ws-12" value={EditValue.intStdRecCode} name="intStdRecCode" onChange={EditHandler} placeholder="Enter ISRC" />
                                </div>
                                <div className="ws-3">
                                    {(EditValue.label) && <button type="button" className="btn btn-success pull-right" onClick={generateISRC}>
                                        <i className="icon-search"></i> Generate ISRC
                                    </button>}
                                </div>
                            </div>
                        </div>

                        <div className="ws-10 form-format m0 mb">
                            <label className="control-label">Artist*</label>
                            <div className="label-entries ws-12">
                                <div className="ws-9">
                                    <input id="artist" type="text" name="artistName" value={EditValue.artistName} placeholder="Enter Artist Name" />
                                </div>

                                <div className="ws-3">
                                    <button type="button" onClick={() => setslider(true)} className="btn-success " ><i className="icon-user-add"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="ws-10 form-format m0 mb">
                            <label className="control-label">Title*</label>
                            <div className="label-entries">
                                <input key="trackName" type="text" name="trackName" value={EditValue.trackName} onChange={EditHandler} placeholder="Enter Title" />
                            </div>
                        </div>

                        <div className="ws-10 mtl">
                            <label className="control-label text-left ws-12 mt text-bold">Track Image</label>

                            <div className="ws-12 pm mb">
                                <div className="ws-5">

                                    {(EditValue.image) && <div className="box shadowCoverPicture shadow ws-4" >
                                        <div className="coverPicture" style={{ height: "100%" }}>
                                            <img src={EditValue.image} style={{ height: "100%" }} />
                                            <img className="ws-12 hide" />
                                        </div>
                                    </div>}

                                    {!(EditValue.image) && <table className="coverPictureContainer">
                                        <tbody><tr>
                                            <td style={{ verticalAlign: "middle" }}>
                                                <i className="icon-new-registration"></i>
                                            </td>
                                        </tr>
                                        </tbody></table>}
                                </div>

                                <div className="ws-7 ptll">
                                    <label className="btn-primary" style={{ marginTop: "15%" }}>Upload Image
                                        <input type="file" accept="image/*" id="file-upload" className="hide" onChange={handleFileChange} />
                                    </label>
                                    <span className="ws-12 mt">Maximum file size 2mb file types accepted .jpg/ .png/ .bmp (Resolution Minimum 300px X 230px).</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    {(saveStatus) && <div className='ws-12'>
                        {<div className="form-format ws-12 mtl box pLarge" >
                            <label className="control-label text-left">Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={ClassificationSearch} placeholder="Type to search for classification" onChange={(e) => setClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => ClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(ClassificationSearch.length > 3) && <div>
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => ClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => ClassificationSave(values.useFreeText)} /> </td>
                                                    <td>{values.useFreeText}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                                {
                                    (() => {
                                        if (ClassificationtotalRow && ClassificationtotalRow > 10) {
                                            return <Pagination onChange={handleNameOnChange} totalRows={ClassificationtotalRow} />
                                        }

                                    })()

                                }

                            </div>

                            {(associatedclassification.length > 0) && <div className="form-format ws-12 mtl" ng-show="directoryClassifications.length > 0">
                                <label className="control-label text-left w-30 mt">Associated Classification(s):</label>
                                {
                                    associatedclassification?.map((values: any, index: any) => {

                                        return (
                                            <div className=''>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "RGB(255,255,255)" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.categoryName}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteTaging(values.creatorCategoryId)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                            </div>}
                            {(ClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                <div className="alert alert-info">No classification available  </div>
                            </div>}

                        </div>}

                        {<div className="form-format ws-12 mtl box pLarge">
                            <label className="control-label text-left">Sub Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={SubClassificationSearch} placeholder="Type to search for Sub classification" onChange={(e) => setSubClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => SubClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(SubClassificationSearch.length > 3) && <div>
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => SubClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listSubClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => SubClassificationSave(values.code)} /> </td>
                                                    <td>{values.code}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                                {
                                    (() => {
                                        if (SubClassificationtotalRow && SubClassificationtotalRow > 10) {
                                            return <Pagination onChange={handleNameOnChangeSub} totalRows={SubClassificationtotalRow} />
                                        }

                                    })()

                                }

                            </div>

                            {(associatedSubclassification.length > 0) && <div className="form-format ws-12 mtl">
                                <label className="control-label text-left w-30 mt ">Associated Sub Classification(s):</label>

                                {
                                    associatedSubclassification?.map((values: any, index: any) => {

                                        return (
                                            <div>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "#fff" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.text}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteSubTaging(values.id)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                            </div>}
                            {(SubClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                <div className="alert alert-info">No Subclassification available</div>
                            </div>}

                        </div>}
                    </div>}
                </div>

                <div className="box-footer">

                    <div className="pull-right">
                        <button className="btn-success pull-right" onClick={SaveAsset}> <i className="icon-save"></i> Save </button>
                        <button className="btn-pink pull-right" onClick={closePage}> <i className="icon-close"></i> Close </button>
                    </div>
                </div>

            </div>


        </Drawer>

        {
            (() => {

                if (slider == true) {
                    return <div className='ws-12'><SelectContact onChangeContact={onChangeContact} data={EditValue.artistName} hide={true} open={slider} /></div>
                }
            })()
        }

    </>)
}