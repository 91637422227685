import { CommonUtilities } from "../../commonUtils";
import { Modal } from 'react-responsive-modal';
import React, { Suspense, useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../Header/Pagination";
import { format } from 'date-fns';
import DatePickerRange from "../PeriodSelection/DatePickerRange";
import ClientProfileSevices from "./clientProfileServices";

export default function Email(iconName :any) {

    const [openFirst, setOpenFirst] = React.useState(false);
    const [totalRows, setTotalRows] = useState(1);
    const [EmailListing, initEmailListing] = useState<any[]>([]);
    const [value, setValue] = React.useState('All Filters');
    const [state, setState] = React.useState("");
    const [checkbox, setcheckbox] = useState<any[]>([]);
    const [navOpen, setnavOpen] = React.useState(false);
    let [checkBox, setcheckBox] = useState({ All: true, open: true, bounce: true, deferred: true, delivered: true, dropped: true, processed: true });

    var EmailObj = {
        event: "open,bounce,deferred,delivered,dropped,processed",
        email: '',
        dateFrom: '',
        dateTo: '',
        pageSize: 10,
        pageNumber: 1,
        queueId: ''
    }

    const handleNameOnChange = (n: any) => {
        console.log(n)
        EmailObj.pageNumber = n.pageNumber;
        EmailObj.pageSize = n.pageSize;
        getEmail(0);
    }

    const getEmail = (e: any) => {

        var eventList = ""
        if (checkBox.open == true) { eventList += "open," }

        if (checkBox.bounce == true) { eventList += "bounce," }

        if (checkBox.deferred) { eventList += "deferred," }

        if (checkBox.delivered) { eventList += "delivered," }

        if (checkBox.dropped) { eventList += "dropped," }

        if (checkBox.processed) { eventList += "processed" }

        if ((!checkBox.open) && (!checkBox.bounce) && (!checkBox.deferred) && (!checkBox.delivered) && (!checkBox.dropped) && (!checkBox.processed)) {
            eventList += "open,bounce,deferred,delivered,dropped,processed"
        }
        EmailObj.event = eventList;
        if (e == 0) {
            ClientProfileSevices.EmailListing(EmailObj).then((res) => {
                initEmailListing(res)
                if (res[0].totalRows > 0) { setTotalRows(res[0].totalRows) }
                else if (res.length == 0) { setTotalRows(0) }
            })

        } else {
            ClientProfileSevices.EmailExport({
                "event": EmailObj.event, "queueId": EmailObj.queueId,
                "email": EmailObj.email, "dateFrom": EmailObj.dateFrom, "dateTo": EmailObj.dateTo, "export": 1
            });
        }


        /* ClientProfileSevices.EmailListing(EmailObj).then((res) => {
             initEmailListing(res)
             if(res[0].totalRows > 0){setTotalRows(res[0].totalRows)}
             else if(res.length == 0){setTotalRows(0) }            
         })*/
    }

    const handleChange = (event: any) => {

        setValue(event.target.value);
        if (event.target.value !== 'All') {
            EmailObj.event = event.target.value;
        } else {
            EmailObj.event = "open,bounce,deferred,delivered,dropped,processe";
        }

        getEmail(0);
    };

    const handleData = (e: any) => {

        if (e == 'e') {
            EmailObj.email = state;
        }

        getEmail(0);
    }

    const options = [
        { label: 'All Filters', value: 'All' },
        { label: 'open', value: 'open' },
        { label: 'bounce', value: 'bounce' },
        { label: 'deferred', value: 'deferred' },
        { label: 'delivered', value: 'delivered' },
        { label: 'dropped', value: 'dropped' },
        { label: 'processed', value: 'processed' }
    ];

    const dateSelection = (e: any) => {
        if (e != null) {

            if (e[0] != null) {
                EmailObj.dateFrom = format(new Date(e[0]), 'yyyyMMdd');
            }

            if (e[1] != null) {
                EmailObj.dateTo = format(new Date(e[1]), 'yyyyMMdd');
            }
        }
    }

    const handleCheckBox = (e: any) => {


        var value = e.target.checked;
        if (e.target.value == 'open') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: value, bounce: checkBox.bounce, deferred: checkBox.deferred, delivered: checkBox.delivered, dropped: checkBox.dropped, processed: checkBox.processed
            }))
            getEmail(0);
        } else if (e.target.value == 'deferred') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: checkBox.open, bounce: checkBox.bounce, deferred: value, delivered: checkBox.delivered, dropped: checkBox.dropped, processed: checkBox.processed
            }))
            getEmail(0);
            // checkBox.deferred = !(checkBox.deferred)
        } else if (e.target.value == 'delivered') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: checkBox.open, bounce: checkBox.bounce, deferred: checkBox.deferred, delivered: value, dropped: checkBox.dropped, processed: checkBox.processed
            }))
            getEmail(0);
            //checkBox.delivered = !(checkBox.delivered)
        } else if (e.target.value == 'dropped') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: checkBox.open, bounce: checkBox.bounce, deferred: checkBox.deferred, delivered: checkBox.delivered, dropped: value, processed: checkBox.processed
            }))
            getEmail(0);
            // checkBox.dropped = !(checkBox.dropped)
        } else if (e.target.value == 'bounce') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: checkBox.open, bounce: value, deferred: checkBox.deferred, delivered: checkBox.delivered, dropped: checkBox.dropped, processed: checkBox.processed
            }))
            getEmail(0);
            /// checkBox.bounce = !(checkBox.bounce)
        } else if (e.target.value == 'processed') {
            setcheckBox(existingValues => ({
                ...existingValues,
                open: checkBox.open, bounce: checkBox.bounce, deferred: checkBox.deferred, delivered: checkBox.delivered, dropped: checkBox.dropped, processed: value
            }))
            getEmail(0);
            //checkBox.processed = !(checkBox.processed)
        } else if (e.target.value == 'All') {
            setcheckBox(existingValues => ({
                ...existingValues,
                All: value, open: value, bounce: value, deferred: value, delivered: value, dropped: value, processed: value
            }))
            getEmail(0);
        }

    }

    const returnDate = (e: any) => {
        var ts = new Date(e * 1000).toUTCString();
        //var tds = new Date(e * 1000).toDateString;
        //const date= new Date(e).toDateString();
        //var time = tds.getHours + " " + tds.getMinutes+ " " + tds.getSeconds

        return ts.toString()
    }

    const EmailFeedClose=()=>{
        iconName.EmailFeedClose();
    } 

    useEffect(() => {       
        if(iconName.openEmailFeed == true){                 
            getEmail(0);
        }   

    }, [iconName.openEmailFeed]);

    return (
        <div className="pull-left"> 

            <Modal open={iconName.openEmailFeed} onClose={() => EmailFeedClose()}>
                <>
                    <div className="box-header-main">
                        <h2 className="ws-4"> Email History</h2>
                        <i className="fa fa-envelope"></i>
                        
                    </div>

                    <div className="box-content">

                        <div className="box-content">

                            <DatePickerRange onChange={dateSelection} />

                            <div className="btn pull-left btn-success mtll"><i className="icon-search" onClick={() => handleData('SD')} /></div>

                            <div className="pull-right hide">
                                <select value={value} onChange={handleChange}>

                                    {options.map((option) => (

                                        <option value={option.value}>
                                            <div role="processed_circle" className={"metric-circle " + option.value}></div><input type="checkbox" />{option.label}
                                        </option>

                                    ))}

                                </select>
                            </div>

                            <div className="btn btn-secondary btn-dropdown dropdown is-active pull-right mtl" style={{ paddingRight: "62px" }} onClick={() => setnavOpen(true)}>


                                <div role="processed_circle" className={checkBox.open ? "metric-circle open " : "hide"}></div>
                                <div role="processed_circle" className={checkBox.bounce ? "metric-circle bounce" : "hide"}></div>
                                <div role="processed_circle" className={checkBox.deferred ? "metric-circle deferred" : "hide"}></div>
                                <div role="processed_circle" className={checkBox.delivered ? "metric-circle delivered" : "hide"}></div>
                                <div role="processed_circle" className={checkBox.dropped ? "metric-circle dropped" : "hide"}></div>
                                <div role="processed_circle" className={checkBox.processed ? "metric-circle processed" : "hide"}></div>


                                {navOpen && <div className="drop-menu">
                                    <div className="dropdown-link" style={{ display: "inline" }}>
                                        <input type="checkbox" value="All" checked={checkBox.All} onClick={(e: any) => { handleCheckBox(e); }} /> All
                                    </div>

                                    <div><div role="processed_circle" className={"metric-circle open"}></div>
                                        <input type="checkbox" value="open" checked={checkBox.open} onClick={(e: any) => { handleCheckBox(e) }} /> open</div>

                                    <div><div role="processed_circle" className={"metric-circle bounce"}></div>
                                        <input type="checkbox" value="bounce" checked={checkBox.bounce} onClick={handleCheckBox} /> bounce</div>

                                    <div><div role="processed_circle" className={"metric-circle deferred"}></div>
                                        <input type="checkbox" value="deferred" checked={checkBox.deferred} onClick={handleCheckBox} /> deferred</div>

                                    <div><div role="processed_circle" className={"metric-circle delivered"}></div>
                                        <input type="checkbox" value="delivered" checked={checkBox.delivered} onClick={handleCheckBox} /> delivered</div>

                                    <div><div role="processed_circle" className={"metric-circle dropped"}></div>
                                        <input type="checkbox" value="dropped" checked={checkBox.dropped} onClick={handleCheckBox} /> dropped</div>

                                    <div><div role="processed_circle" className={"metric-circle processed"}></div>
                                        <input type="checkbox" value="processed" checked={checkBox.processed} onClick={handleCheckBox} /> processed</div>

                                </div>}

                            </div>

                            <div className="dropdown-link ws-1 btn-dropdown dropdown  is-active pull-right hide">
                                {
                                    (() => {
                                        if (navOpen == true) {
                                            return (
                                                options.map((option) => (
                                                    <li>
                                                        <input type="checkBox" /> <div role="processed_circle" className={"metric-circle " + option.value}></div>
                                                        <label className="input-checkbox-label">{option.value}</label>
                                                    </li>
                                                ))

                                            )
                                        }
                                    })()
                                }
                            </div>

                        </div>

                        <div className="box-content">
                            <div className="ws-12">
                                <table className="box pbl">
                                    <thead>
                                        <tr>
                                            <th>Contact Code</th>
                                            <th className="ws-12">
                                                <div className="input-text-wrap is-full-width is-search ws-12" >
                                                    <label className="input-text-label pr ws-12" id="name">Search by email</label>
                                                    <input className="input-text pl ws-12"
                                                        value={state} onChange={(e) => { setState(e.target.value); handleData('e') }}></input>
                                                    <span className="input-info danger hidden" role="error"></span>
                                                    <span className="input-info hidden" role="info"></span>
                                                </div>
                                            </th>
                                            <th>Event</th>
                                            <th>TimeStamp</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {EmailListing.map(post => (
                                            <tr>
                                                <td>{post.kontactCode}</td>
                                                <td>{post.email}</td>
                                                <td className="text-bold"><span className={(post.event == 'deferred' || post.event == 'bounce') ? 'label-danger' :
                                                    post.event == 'delivered' ? 'label-success' :
                                                        post.event == 'processed' ? 'label-warning'
                                                            : ""}>{post.event}</span></td>
                                                <td className="hide">{(new Date().toLocaleString(post.timestamp))}</td>
                                                <td >{(returnDate(post.timestamp))}</td>
                                            </tr>
                                        ))}

                                    </tbody>

                                </table>

                            </div>

                            <div className="ptl ws-12">
                                {
                                    (() => {
                                        if (totalRows && totalRows > 10) {
                                            return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                                        }

                                    })()
                                }
                                <button className="btn btn-primary pull-right mt" onClick={() => getEmail(1)}><i className="fa fa-download"></i> Export</button>
                            </div>

                        </div>

                    </div>
                </>
            </Modal>
        </div>
    )
}