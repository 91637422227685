import CommonSearch from "../../components/commonSearch/CommonSearch";
import globalSearchService from "../../components/commonSearch/globalSearchServices";
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import Header from "../../components/Header/header";
import AddAccountSlider from "./Add Slider/AddAccountSlider";
import Pagination from "../Header/Pagination";

export default function Accounts(param: any) {

    useEffect(() => {
        globalSearchService.lookUpTypeList()
            .then((res) => {
                global.lookUpTypeList = res;
            });
    }, []);

    let [activeMenu, setActiveMenu] = useState("Accounts");
    let [activeCategory, setActiveCategory] = useState("All");
    let [totalRow, settotalRow] = useState(0);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    let [directoryViewAll, setdirectoryViewAll] = useState<any[]>([]);
    let [viewAllList, setviewAllList] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    const [perCategory, setperCategory] = useState(false);
    let [selectedDetail, setselectedDetail] = useState<any>([]);
    const [detailView, setdetailView] = useState(false);
    const [detailMainTab, setdetailMainTab] = useState(1);
    let [detailSubTab, setdetailSubTab] = useState("Template");
    let [activedetailCategory, setActivedetailCategory] = useState("All");

    var loop = 0;
    var pagination = {
        'pageSize': 10,
        'pageNumber': 1,
        "display": "Category",
        "type": "Accounts"
    }

    useEffect(() => {
        setActiveCategory("All");
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }

    }, [])

    const LandingListing = () => {
        directoryServices.AccountListing({ ...pagination }).then((res) => {

            res.map((values: any) => {

                if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                    categoryList.push(values.categoryName)
                    setCategoryList(categoryList)
                }
            })
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
        setperCategory(false);
    }

    const SearchForHandle = (e: any, sts: any) => {
        console.log(e)
        console.log(sts)

        if ((sts == true)) {

            directoryServices.AccountListing({ ...e, ...pagination }).then((res) => {
                console.log(res)


                res.map((values: any) => {

                    if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            })
        }
        setperCategory(false);
    }

    const viewAll = (e: any) => {
        // console.log(e)
        if (viewAllList == false) {
            directoryServices.AccountListingAll({ ...{ "categoryName": e.categoryName }, ...pagination }).then((res) => {
                setdirectoryViewAll(res)
                //setSubtotalRow (res[0]?res[0].totalRows : 0)
            })
        }
    }

    const viewCategory = (e: any) => {
        console.log(e)

        directoryServices.AccountListingAll({ ...{ "categoryName": e }, ...pagination }).then((res) => {
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)
        })

    }

    var creatorAssetPayLoad = {
        assetType: "T",
        categoryName: "",
        AssetImprint: selectedDetail.code,
        display: "Category",
        pageNumber: 1,
        pageSize: 10,
    }

    var bundlePayLoad = {
        pageNumber: 1,
        pageSize: 10,
        bundleImprint: selectedDetail.code
    }

    let [creatorAssetList, setcreatorAssetList] = useState<any>([]);
    let [creatorAssetCategory, setcreatorAssetCategory] = useState<any>([]);
    let [creatorAssettotalRow, setcreatorAssettotalRow] = useState(0);

    var SubclassFicationPayload = {
        area: "Accounts",
        referenceCode: "",
        text: "",
        pageNumber: 1,
        pagesize: 10
    }

    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    const creatorAsset = (e: any) => {

        if (e != "All") {
            creatorAssetPayLoad.categoryName = e;
        } else { creatorAssetPayLoad.categoryName = ""; }

        //console.log(detailSubTab)

        console.log(selectedDetail)
        setselectedDetail(selectedDetail)

        creatorAssetPayLoad.AssetImprint = selectedDetail.code;
        bundlePayLoad.bundleImprint = selectedDetail.code;
        relatedAssetPayLoad.CreatorCode = selectedDetail.code;


        SubclassFicationPayload.referenceCode = selectedDetail.code;
        directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

        directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.code, CategoryType: "Accounts" }).then((res) => {
            setassociatedclassification(res)
        })

    }

    useEffect(() => {
        if (activedetailCategory == "All") {
            creatorAssetCategory = []
            setcreatorAssetCategory(creatorAssetCategory)
        }
        for (var $i = 0; $i < creatorAssetList.length; $i++) {
            if (creatorAssetList[$i].categoryName != ' ' && activedetailCategory == "All" && (creatorAssetCategory.length <= creatorAssetList.length)) {

                creatorAssetCategory.push(creatorAssetList[$i].categoryName)
                setcreatorAssetCategory(creatorAssetCategory)
            }
        }

    }, [creatorAssetList])


    const set_detailSubTab = (e: any) => {
        detailSubTab = e
        setActivedetailCategory('All')
        setdetailSubTab(detailSubTab)
    }


    let [detailSubAliasTab, setdetailSubAliasTab] = useState("")
    const set_detailSubAliasTab = (e: any) => {
        detailSubAliasTab = e
        ///setActivedetailCategory('All')
        setdetailSubAliasTab(detailSubAliasTab)
    }


    var relatedAssetPayLoad = {
        CreatorCode: selectedDetail.code,
        type: "Accounts",
        pageNumber: 1,
        pageSize: 10
    }

    let [dialogueOpen, setdialogue] = useState(0);


    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false)

    const onAddClose = (e: any) => {
        console.log("")
        setdialogueAddOpenView(false)
    }

    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }

    var TemplatePayload = {
        kontactCode: "E-One",
        pageNumber: 1,
        pageSize: 10,
        templateId: ""
    }
    const [TemplateListing, setTemplateListing] = useState<any>([]);

    const getTemplate = () => {
        console.log(selectedDetail)
        TemplatePayload.kontactCode = selectedDetail.code;
        directoryServices.AccountTemplate(TemplatePayload).then((res: any) => {
            setTemplateListing(res)
            setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    let [Analysisinvoices, setAnalysisinvoices] = useState("");
    let [AnalysisinvoicesList, setAnalysisinvoicesList] = useState<any>([]);
    const getInvoiceList = (param: any) => {
        setAnalysisinvoices(param)
        console.log(selectedDetail)
        directoryServices.salesAnalysisinvoices(param, { SearchFor: selectedDetail.code }).then((value: any) => {
            //console.log(value)
            setAnalysisinvoicesList(value)
            console.log(value.length)
            setcreatorAssettotalRow(value ? value.length : 0)
        })
    }


    var payLoad = {
        pageNumber: 1,
        pageSize: 10
    }

    const [templateFullListing, setTemplateFullListing] = useState<any>([]);
    const fePagination = (e: any) => {

        payLoad.pageNumber = e.pageNumber;
        payLoad.pageSize = e.pageSize;
        setTemplateListing([])
        setTemplateListing(templateFullListing.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));
    }

    const aliasListing = () => {

        if (detailSubAliasTab == 'Territory') {
            directoryServices.AccountTerritoryAliases({ pageNumber: 1, pageSize: 10, }).then((res: any) => {
                setTemplateListing(res);
                setcreatorAssettotalRow(res.length);
                //fePagination(payLoad)

                setTemplateFullListing(res)
                setTemplateListing([])
                setTemplateListing(res.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));
            });
        }

        if (detailSubAliasTab == 'Formats') {
            directoryServices.AccountFormatAlias({ pageNumber: 1, pageSize: 10, }).then((res: any) => {
                setTemplateListing(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0);
                //fePagination(payLoad);
                setTemplateFullListing(res)
                setTemplateListing([])
                setTemplateListing(res.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));
            })
        }

        if (detailSubAliasTab == 'CategoryAliases') {
            directoryServices.AccountRelAliases({ pageNumber: 1, pageSize: 10, }).then((res: any) => {
                setTemplateListing(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0);
                //fePagination(payLoad)
                setTemplateFullListing(res)
                setTemplateListing([])
                setTemplateListing(res.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));
            })
        }

        if (detailSubAliasTab == 'SongAliases') {
            directoryServices.AccountRelAliasesPub({ pageNumber: 1, pageSize: 10, }).then((res: any) => {
                setTemplateListing(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0);
                //fePagination(payLoad);
                setTemplateFullListing(res)
                setTemplateListing([])
                setTemplateListing(res.slice((payLoad.pageNumber - 1) * payLoad.pageSize, payLoad.pageSize * payLoad.pageNumber));
            })
        }

    }


    return (

        <>
            <div className="landing ws-12">

                <div className='ws-12 bgWhite hide'>
                    <Header Title={"Directory"} />
                </div>

                <div className="ws-12">

                    <div className="box-header-main">
                        <h2>Music Directories</h2>
                    </div>

                    <div className="ws-2 box mtxl pm">
                        <ul className="main-menu">
                            <a className="menu-header">Music Directories</a>
                            <ul>
                                {
                                    directoryServices.directoryMenu?.map((value: any) => {
                                        return (
                                            <>
                                                {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                                </li>}
                                            </>
                                        )
                                    })
                                }
                            </ul>
                            <a className="menu-header">Data Directories</a>

                            <ul>
                                {
                                    directoryServices.directoryMenu?.map((value: any) => {
                                        return (
                                            <>
                                                {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                    <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                                </li>}
                                            </>
                                        )
                                    })
                                }

                            </ul>
                        </ul>

                        <div className="main-menu-span m0 box ws-12 fullHeight hide">
                            <div className="sidebar-nav ws-12">
                                <ul className="nav nav-stacked main-menu m0 ws-12" id="mainMenu">
                                    <li key="MusicDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                        <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Music Directories</a>

                                        <ul className="nav nav-tabs full-width">
                                            {
                                                directoryServices.directoryMenu?.map((value: any) => {
                                                    return (
                                                        <>
                                                            {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                                <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                            </li>}
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </li>

                                    <li key="DataDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                        <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Data Directories</a>

                                        <ul className="nav nav-tabs full-width">
                                            {
                                                directoryServices.directoryMenu?.map((value: any) => {
                                                    return (
                                                        <>
                                                            {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                                <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                            </li>}
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="ws-10">
                        {(detailView == false) && <><div className="pLarge">

                            <div className="ws-9">
                                <ul className="nav nav-tabs p0 m0 green-nav-tabs">
                                    <li key='All' className={activeCategory == 'All' ? "active " : ""}
                                        onClick={() => { setActiveCategory('All'); setperCategory(false); LandingListing(); }}>All</li>
                                    {
                                        categoryList?.map((list: any) => {
                                            return (
                                                <li key={list} className={activeCategory == list ? "active " : ""}
                                                    onClick={() => { setActiveCategory(list); setperCategory(true); viewCategory(list) }}>{list}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="ws-3">
                                <div className="verticalMediumWide hover-box">
                                    <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2 mr">
                                        <li key='Add Account' className="ws-12 pbs">
                                            <a onClick={() => { setdialogueAddOpenView(true);setselectedDetail([]) }}>Add new Account</a>
                                        </li>
                                    </ul>
                                    <button className="btn btn-info pull-right pt">
                                        <i className="icon-grid m0 p0"></i>
                                    </button>
                                </div>

                            </div>

                            <hr />
                            <div className="ws-12 ">
                                <CommonSearch SearchForHandle={SearchForHandle} type="contactGrid" title="Accounts" width="ws-10 pull-left" />
                            </div>

                            <div className="ws-12">
                                <div className="box-content">
                                    <h2>Showing <span className="info">{totalRow}</span> results</h2>
                                </div>
                            </div>

                        </div>

                            <div className="ws-12 landing pLarge">

                                {(!perCategory && directoryFullListing.length > 0) && <>
                                    {
                                        directoryFullListing?.map((valueObj: any) => {
                                            return (
                                                <>
                                                    {(valueObj && valueObj.categoryName != " ") && <h2 className="ws-12">
                                                        <span className="text-bold pt">{valueObj.categoryName}</span>
                                                        <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                        {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                    </h2>}

                                                    {(valueObj && valueObj.categoryName != " ") && <div>
                                                        {
                                                            valueObj.categoryDetails?.map((value: any, index: any) => {

                                                                if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                    return (

                                                                        <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                <div className="coverPicture">

                                                                                    <img className="ws-12" src={"data:image/jpeg;base64,"+value.logo} />

                                                                                </div>
                                                                                <div className="bottomOverlayLabel">
                                                                                    <label className="control-label ws-12"> {value.fullName}</label>
                                                                                    <label className="control-label ws-12 hide"> {value.fullName} </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>}</>

                                                                    )
                                                                }

                                                                if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                    return (<>
                                                                        {directoryViewAll.map((values) => {
                                                                            return (
                                                                                <div className="creatorImg pm pull-left">

                                                                                    <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                        <div className="coverPicture">

                                                                                            <img className="ws-12" src={values.logo} />

                                                                                        </div>
                                                                                        <div className="bottomOverlayLabel">
                                                                                            <label className="control-label ws-12"> {values.fullName}</label>
                                                                                            <label className="control-label ws-12"> {values.fullName} </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                        })}</>)
                                                                }
                                                            })
                                                        }
                                                    </div>}
                                                </>
                                            )
                                        })
                                    }

                                    {
                                        directoryFullListing?.map((valueObj: any) => {
                                            return (
                                                <>
                                                    {(valueObj.categoryName == " ") && <h2 className="ws-12">
                                                        <span className="text-bold pt"> Uncategorised</span>
                                                        <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                        {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                    </h2>}

                                                    {(valueObj.categoryName == " ") && <div >
                                                        {
                                                            valueObj.categoryDetails.map((value: any, index: any) => {
                                                                if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                    return (

                                                                        <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                <div className="coverPicture">

                                                                                    <img className="ws-12" src={value.logo} />

                                                                                </div>
                                                                                <div className="bottomOverlayLabel">
                                                                                    <label className="control-label ws-12"> {value.fullName}</label>
                                                                                    <label className="control-label ws-12 hide"> {value.fullName} </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>}</>

                                                                    )
                                                                }

                                                                if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                    return (<>
                                                                        {directoryViewAll.map((values) => {
                                                                            return (
                                                                                <div className="creatorImg pm pull-left">

                                                                                    <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                        <div className="coverPicture">

                                                                                            <img className="ws-12" src={values.logo} />

                                                                                        </div>
                                                                                        <div className="bottomOverlayLabel">
                                                                                            <label className="control-label ws-12"> {values.fullName}</label>
                                                                                            <label className="control-label ws-12 hide"> {values.fullName} </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>)
                                                                        })}</>)
                                                                }

                                                            })
                                                        }
                                                    </div>}
                                                </>
                                            )
                                        })
                                    }</>}


                                {(perCategory && directoryFullListing.length > 0) && <>

                                    {directoryFullListing?.map((values: any) => {
                                        return (
                                            <div className="creatorImg pm pull-left">

                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values);; creatorAsset("All") }}>

                                                    <div className="coverPicture">

                                                        <img className="ws-12" src={values.logo} />

                                                    </div>
                                                    <div className="bottomOverlayLabel">
                                                        <label className="control-label ws-12"> {values.fullName}</label>
                                                        <label className="control-label ws-12 hide"> {values.fullName} </label>
                                                    </div>
                                                </div>
                                            </div>)
                                    })

                                    }
                                </>}

                            </div></>}

                        {(detailView == true) && <>
                            <div className="ws-12 ">
                                <div className="ws-12 pLarge">
                                    <button className="btn-primary btn-sm mrl" onClick={() => setdetailView(false)}>
                                        <i className="icon-angle-left"></i>
                                    </button>

                                    <div className="mt">
                                        <span className="text-bold pt" style={{ textDecoration: "underline" }}> Accounts directory</span>
                                        <span className="text-bold pt"> {">"} {selectedDetail.code}</span></div>

                                </div>

                                <hr className="mtxl" />

                            </div>

                            <div className="ws-12 pLarge">
                                <div className="ws-12">
                                    <div className=" box  shadow" style={{ width: "200px", height: "200px" }}>
                                        <div className="coverPicture" style={{ height: "200px" }}>
                                            <img className="ws-12 hide" src={selectedDetail.labelImage} />
                                            <img className="ws-12" src={selectedDetail.labelImage} />
                                        </div>
                                    </div>

                                    <div className="ws-8 pll ptll">
                                        <div className="ws-12 text-xl text-bold mbs ptl ">{selectedDetail.code}</div>

                                        <div className=" ">
                                            {
                                                associatedclassification?.map((values: any) => {
                                                    return (
                                                        <span className="btn pull-left btn-black">{values.categoryName}</span>
                                                    )

                                                })
                                            }


                                        </div>

                                        <div className=" ">

                                            {
                                                associatedSubclassification?.map((values: any) => {
                                                    return (
                                                        <span className="btn pull-left btn-blackBorder">{values.text}</span>
                                                    )
                                                })
                                            }
                                        </div>

                                        <span className="ws-12 info text-bold mbl ptl ">{selectedDetail.code}</span>
                                    </div>

                                    <div className="verticalMediumWide hover-box">
                                        <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2">

                                            <li className="ws-12 pbs">
                                                <a onClick={() => { setdialogue(2); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                            </li>

                                        </ul>
                                        <button className="btn btn-info pull-right pt">
                                            <i className="icon-grid m0 p0"></i>
                                        </button>
                                    </div>

                                </div>

                                <div className="ws-12 ">

                                    <ul className="nav nav-tabs ws-12 mbll mtll  ws-12">
                                        <li className={detailMainTab == 1 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(1); }}>Summary</li>{/* */}
                                        <li className={detailMainTab == 2 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(2); }}>Details</li>
                                        <li className={detailMainTab == 3 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(3); getInvoiceList("publishingSong"); }}>Transactions</li>
                                        <li className={detailMainTab == 4 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(4); }}>Analytics</li>
                                        <hr className="ws-12 m0 p0" />
                                    </ul>

                                </div>

                                {(detailMainTab == 1) && <div className="forceSingleLine pull-left">

                                    <ul className="nav nav-tabs ws-12">
                                        <li className={detailSubTab == 'Templates' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubTab('Templates'); getTemplate(); }}>Templates</li>
                                        <li className={detailSubTab == 'Aliases' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubTab('Aliases'); set_detailSubAliasTab('Territory'); aliasListing(); }}> Aliases </li>
                                    </ul>
                                    {(detailSubTab == 'Aliases') && <ul className="nav nav-tabs ws-12">
                                        <li className={detailSubAliasTab == 'Territory' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubAliasTab('Territory'); aliasListing(); }}> Territory</li>
                                        <li className={detailSubAliasTab == 'Formats' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubAliasTab('Formats'); aliasListing(); }}> Formats </li>
                                        <li className={detailSubAliasTab == 'CategoryAliases' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubAliasTab('CategoryAliases'); aliasListing(); }}> Category Aliases</li>
                                        <li className={detailSubAliasTab == 'SongAliases' ? "plxl prxl active" : "plxl prxl"} onClick={() => { set_detailSubAliasTab('SongAliases'); aliasListing(); }}> Song Aliases </li>
                                    </ul>}
                                </div>}

                                {(detailMainTab == 3) && <div className="forceSingleLine pull-left">
                                    <ul className="nav nav-tabs ws-12">
                                        <li className={Analysisinvoices == "publishingSong" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("publishingSong")}> Publishing - Writer</li>
                                        <li className={Analysisinvoices == "publishingWriter" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("publishingWriter")}> Publishing - Song</li>
                                        <li className={Analysisinvoices == "adjustments" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("adjustments")}> Adjustments</li>
                                        <li className={Analysisinvoices == "licensing" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("licensing")}> Licensing</li>
                                        <li className={Analysisinvoices == "physical" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("physical")}> Physical</li>
                                        <li className={Analysisinvoices == "advances" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("advances")}> Advances</li>
                                        <li className={Analysisinvoices == "digital" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("digital")}> Digital</li>
                                        <li className={Analysisinvoices == "costs" ? "plxl prxl active" : "plxl prxl"} onClick={() => getInvoiceList("costs")}> Costs</li>
                                    </ul>
                                </div>}
                            </div>

                            <div className="landing ws-12 pll prl">
                                <div className="box">
                                    <div className="box-header well">
                                        <h2 ><i className="icon-info"></i>  {creatorAssettotalRow} record(s) found</h2>
                                    </div>

                                    <div className="box-content">
                                        {(detailMainTab == 1) && <div className="forceSingleLine pull-left ws-12">
                                            <div className="box-content p0 mtl">
                                                <h2>Showing {detailSubTab}  {detailSubAliasTab}<span className="info">{creatorAssettotalRow}</span> results</h2>

                                                {(creatorAssettotalRow > 0) && <div className="ws-9">

                                                    <table>
                                                        <thead>

                                                            <tr>
                                                                {
                                                                    directoryServices.accountFiled.map((values: any) => {
                                                                        if (values.route == detailSubTab) {
                                                                            return <th>{values.header}</th>
                                                                        }

                                                                        if (values.route == detailSubAliasTab) {
                                                                            return <th>{values.header}</th>
                                                                        }

                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>

                                                        <tbody>

                                                            {
                                                                TemplateListing?.map((value: any) => {
                                                                    return (
                                                                        <tr>
                                                                            {
                                                                                directoryServices.accountFiled.map((values: any) => {
                                                                                    if (values.route == detailSubTab) {
                                                                                        return <td>{value[values.mapping]}</td>
                                                                                    }

                                                                                    if (values.route == detailSubAliasTab) {
                                                                                        return <td>{value[values.mapping]}</td>
                                                                                    }

                                                                                })
                                                                            }

                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>

                                                </div>}
                                            </div>

                                        </div>}

                                        {(detailMainTab == 2) && <div className="forceSingleLine pull-left">  </div>}
                                        {(detailMainTab == 3) && <div className="forceSingleLine pull-left ws-12">

                                            {(creatorAssettotalRow > 0) && <table className="ws-11 mtl">
                                                <thead>
                                                    <tr>
                                                        <th>Account Code</th>
                                                        <th>header Type</th>
                                                        <th>transaction Ref</th>
                                                        <th>invoiceNum</th>
                                                        <th>Operation</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        AnalysisinvoicesList.map((values: any) => {
                                                            return (

                                                                <tr>
                                                                    <td>{values.accountCode}</td>
                                                                    <td>{values.headerType}</td>
                                                                    <td>{values.transactionRef}</td>
                                                                    <td>{values.invoiceNum}</td>
                                                                    <td><a className="btn btn-xs btn-danger"> <i className="icon-trashcan"></i></a></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>}
                                        </div>}
                                        {(detailMainTab == 4) && <div className="forceSingleLine pull-left">  </div>}

                                        {(creatorAssettotalRow == 0) &&
                                            <div className="ws-12 mtl">
                                                <span className="alert alert-success ws-12 text-center">No data Available</span>
                                            </div>
                                        }


                                        {
                                            (() => {
                                                if (creatorAssettotalRow && creatorAssettotalRow > 10) {
                                                    return <Pagination onChange={fePagination} totalRows={creatorAssettotalRow} />
                                                }
                                            })()
                                        }
                                    </div>
                                </div>

                            </div>

                        </>}

                    </div>

                </div>

                {
                    (() => {

                        if (dialogueAddOpenView) {
                            return (<AddAccountSlider onChangeClose={onAddClose} open={dialogueAddOpenView} selectedDetail={selectedDetail} />)
                        }
                    })()
                }
            </div>
        </>
    )
}