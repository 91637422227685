import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';

export default function DataLoaderTableConfirmation(param: any) {
    const [openFirst, setOpenFirst] = React.useState(false);

    const returnValue = (e: any) => {
        param.onClearConfirmation(e)
        setOpenFirst(false)
    }


    return (
        <div  style={{ width: "50%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header-fix" >

                    <div className="box-header-main">
                        <h2><i className="fa fa-question-circle"></i> Clear Table?</h2>
                    </div>

                    <div className="box-content test"><span>Are you sure you want to clear the table?</span></div>

                    <div className="box-footer">
                        <button className="btn-danger" onClick={() => returnValue(2)} > Cancel</button>
                        <button className="btn-success" onClick={() => returnValue(1)} > Confirm</button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}