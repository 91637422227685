import React, { Suspense, useState, useEffect, useRef } from "react";
import { Modal } from 'react-responsive-modal';
import directoryServices from "../directoryServices";

export default function LoadFile(param: any) {
    const [openFirst, setOpenFirst] = React.useState(false);

    const returnValue = (e: any) => {
        param.onChangeLoadFile(e)
        setOpenFirst(false)
    }


    const [assetUploaderCount, setassetUploaderCount] = useState(0)
    useEffect(() => {
        directoryServices.assetUploaderCount({ "type": param.type }).then((res: any) => {
            setassetUploaderCount(res)
        })
    }, []);

    return (
        <div  style={{ width: "50%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header-fix" >

                    <div className="box-header-main">
                        <h2><i className="fa fa-question-circle"></i> Clear or Append Data?</h2>
                    </div>

                    <div className="box-content test"><span>Do you want to clean previous data or append new data ?</span></div>

                    <div className="box-footer">
                        {(assetUploaderCount == 0) && <button className="btn-primary" onClick={() => returnValue(3)} > Load</button>}
                        {(assetUploaderCount > 0) && <>
                            <button className="btn-danger" onClick={() => returnValue(1)} > Append</button>
                            <button className="btn-primary" onClick={() => returnValue(2)} > Clear</button></>}
                    </div>

                </div>
            </Modal>
        </div>
    )
}