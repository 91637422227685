import React, { useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer';
import commonServices from "./commonServices";
import { CommonUtilities } from "../../commonUtils";
import Pagination from "../../components/Header/Pagination";

export default function MonitoringSlider(event: any) {

    let [execListing, setexecListing] = useState<any>([]);
    let [execHeader, setexecHeader] = useState<any>([]);
    let [totalRows, setTotalRows] = useState(0);
    let [SearchFor, setSearchFor] = useState("");

    const toggleDrawer = () => { event.CloseDetail(true) }
    const Data: any = { pageNumber: 1, pageSize: 10 }

    console.log(event)
    const SearchResult = () => {
        var payload = { 
            MonitoringType: event?.errorName?.errorType , 
            ErrorMessage: event?.errorName?.errorName, 
            MonitoringClient: event?.errorName?.dBNAME, 
            PageNumber: Data.pageNumber, 
            PageSize: Data.pageSize ,
            SearchFor : SearchFor
        }

        commonServices.MonitorDetail(payload).then((values) => {
            setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
            setTotalRows(values.totalRows)
            execHeader = [];
            Object.entries(values.headers.display).forEach(([key, value]) => { execHeader.push(value) });
            setexecHeader(execHeader)
            console.log(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
        })
    }

    const handleNameOnChange = (n: any) => {
        Data.pageNumber = n.pageNumber;
        Data.pageSize = n.pageSize;
        SearchResult();
    }

    const download = () => {
        var payload = { 
            MonitoringType  : event?.errorName?.errorType , 
            ErrorMessage    : event?.errorName?.errorName, 
            MonitoringClient: event?.errorName?.dBNAME, 
            PageNumber      : Data.pageNumber, 
            PageSize        : Data.pageSize ,
            type            : "errorListing",
            exportType      : "errorListing"
        }

        commonServices.excelGenericExport(payload) 
    }
    
    useEffect(() => {
        SearchResult();
    }, []);

    return (<>

        <Drawer open={event.openDetail}
            onClose={toggleDrawer}
            direction='right'
            className='react-drawer-left full-height-vh allowHscroll' >

            <div className='box ws-6 pull-right'  >

                <div className="box-header  noBorderRadius bg-blue ws12">
                    <h2 style={{ color: "White" }}>{event?.errorName?.errorName}</h2>
                </div>

                <div className="ws-12 pull-right pLarge"> 
 
                    <fieldset className="scheduler-border p0 m0">
                        <legend className="scheduler-border">
                            <h2 style={{ color: "#47529d" }}>
                                <i className="fa fa-sticky-note-o"></i> Error Info
                            </h2>
                        </legend>
                        
                        <div className="ws-12">

                            <div className="ws-5">
                                <div className="form-format">
                                    <label className="control-label ws-4 text-right pr"> Error Name : </label>
                                    <div className="label-entries ws-8"> {event?.errorName?.errorName} </div>
                                </div>
                            </div>

                            <div className="ws-7">
                                <div className="form-format">
                                    <label className="control-label ws-2 text-right pr"> Client Name </label>
                                    <div className="label-entries ws-10"> {event?.errorName?.dBNAME} </div>
                                </div>
                            </div> 

                        </div> 
                    </fieldset>  

                    <div className="ws-12 mbl">
                        <div className="ws-9">
                            <input type="text" className="m0 ws-12 heightReset noBorderRadius" value={SearchFor} placeholder="Type to search" onChange={(e) => setSearchFor(e.target.value)} />
                        </div>
                        <button className="btn-success ws-2 text-center" onClick={()=>SearchResult()} >
                             Search
                        </button>

                    </div>

                    <div className="ws-12 text-bold mbl">
                        <span className="pull-left mt">Showing ( {totalRows} ) results </span>
                    </div>                   

                    <div className="ws-12 pull-left box pll">

                        <div className="table-responsive mrl allowVscroll">

                            <table className="table mbxl mtl">
                                <tr>
                                    {
                                        execHeader.map((values: any, index: any) => {
                                            if (values != 'totalRow') { return (<> <th> {values} </th> </>) }
                                        })
                                    }
                                </tr>

                                {
                                    execListing.map((value: any) => {
                                        return (<>
                                            <tr>
                                                {
                                                    execHeader.map((Hvalues: any, index: any) => {
                                                        if (Hvalues != 'totalRow') { return (<> <td> {value[Hvalues]} </td> </>) }
                                                    })
                                                }

                                            </tr>
                                        </>)
                                    })
                                }
                            </table>
                        </div>

                        <div className="ws-12">
                            {
                                (() => {
                                    if (totalRows && totalRows > 10) {
                                        return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                                    }
                                })()
                            }</div>
                    </div>

                    <div className="box-footer alignBottom">
                        <button type="button" className="btn-primary pull-right" onClick={() => download()}><i className="icon-download1"></i>  </button>
                        <button className="btn-danger pull-right hide" onClick={() => SearchResult()}>Search</button>
                        <button className="btn-danger pull-right" onClick={() => toggleDrawer()}>Close</button>
                    </div>
                </div>
            </div>
        </Drawer>

    </>)

}