import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import SpotifyScraping from "./SpotifyScraping";
import YoutubeScraping from "./YoutubeScraping";
import LinkedTracksScraping from "../MetaData/LinkedTracksScraping";
import ItunesScraping from "./ItunesScraping";
import scrapingServices from "./scrapingServices";
import GenericScraping from "./GenericScraping";


export default function ScrapingTool(subEntity: any) {

    const [showtab, setShowTab] = useState(false);
    const [activeTab, setActiveTab] = useState('iTunes');
    const [res, setRes] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);

    const handleSelectItem = (item:any) => {
       
        setSelectedItems((prevItems:any) => [...prevItems, item]);
      };


    const apiCall = () => {
        scrapingServices.getDSPList()
            .then((response) => {
                setRes(response); // Store the API response in res
                console.log(response);
            })
            .catch((error) => {
                console.error("Error fetching API data:", error);
            });
    }


    return (
        <div className="pull-left">
            <div className="box-primary text-center iconLogo pull-left">
                <div className="ws-12" onClick={() => { setShowTab(true); apiCall(); }}>
                    <i className={'box icon-' + subEntity.subEntity.subEntityCode} />
                    <span className="m0 pts" >{subEntity.subEntity.subEntity} </span>
                </div>
            </div>

            <Modal open={showtab} onClose={() => setShowTab(false)} center >



                <div className="ws-12">

                    <div className="box-header">
                        Music Landscape
                    </div>
                    <div className="box">

                        <ul className="nav nav-tabs  mll mtl">
                            {res.map((item: any) => (
                                <li  key={item.name}
                                    className={activeTab === item.name ? 'active btn nav-horizontal' : 'btn nav-horizontal'}
                                    onClick={() => { setActiveTab(item.name); }} >
                                    {item.name}
                                </li>


                            ))}
                                <li  key={"Admin"}
                                    className={activeTab === "Admin" ? 'active btn nav-horizontal' : 'btn nav-horizontal'}
                                    onClick={() => { setActiveTab("Admin"); }} >
                                    {"Admin"}
                                </li>
                        </ul>


                        <div className="box-content">
                            <div className="ws-6 ">
                                {activeTab === 'Admin' && <GenericScraping onSelectItem={handleSelectItem}/>}
                                {activeTab === 'Spotify' && <SpotifyScraping onSelectItem={handleSelectItem}/>}
                                {activeTab === 'YouTube' && <YoutubeScraping onSelectItem={handleSelectItem}/>}
                                {activeTab === 'iTunes' && <ItunesScraping onSelectItem={handleSelectItem} />}
                            </div>

                            <div className="ws-6 pll">
                                <LinkedTracksScraping selectedItems={selectedItems}/>
                            </div>

                        </div>
                    </div>


                </div>


            </Modal>
        </div>
    )
}
