import React, { Suspense, useState, useEffect, useRef } from "react";
import Drawer from 'react-modern-drawer';
import { CommonUtilities } from "../../commonUtils";
import PeriodService from "./PeriodService";
import { format } from 'date-fns';

export default function PeriodSelectionForm(Param: any) {

    let [dateFrom, setdateFrom] = React.useState({ month: "", year: "" })
    let [dateTo, setdateTo] = React.useState({ month: "", year: "" })
    let [periodSelection, setPeriodSelection] = React.useState<any[]>([]);
    var [selectedMonthFrom, setselectedMonthFrom] = React.useState("");
    var [selectedYearFrom, setselectedYearFrom] = React.useState("");
    var [selectedMonthTo, setselectedMonthTo] = React.useState("");
    var [selectedYearTo, setselectedYearTo] = React.useState("");
    var [accountingPeriod, setAccountingPeriod] = useState(1);
    var [monthList, setMonthList] = useState<any[]>([]);
    var [yearList, setYearList] = useState<any[]>([]);
    let [isOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        selectAccountingPeriod(accountingPeriod);

        console.log(Param)

        if(Param.periodFrom){
            setSelectedDateFrom({periodTo : Param.periodFrom},"Month" )
            setSelectedDateFrom({periodTo : Param.periodFrom},"Year")
        }

        if(Param.periodTo){
            setSelectedDateTo({periodTo : Param.periodTo},"Month" )
            setSelectedDateTo({periodTo : Param.periodTo},"Year")
        }

    }, []);

    const selectAccountingPeriod = (e: any) => {
        yearList = [];
        monthList = [];

        PeriodService.PeriodListingSelection(e).then((res) => {
            setPeriodSelection(res);
            for (var $i = 0; $i < res.length; $i++) {

                if (e == 3) {
                    if ($i % 2 == 0) { yearList.push(res[$i]); }
                    if ($i < 2) { monthList.push(res[$i]); }
                }
                if (e == 2) {
                    if ($i % 4 == 0) { yearList.push(res[$i]); }
                    if ($i < 4) { monthList.push(res[$i]); }
                }
                if (e == 1) {
                    if ($i % 12 == 0) { yearList.push(res[$i]); }
                    if ($i < 12) { monthList.push(res[$i]); }
                }
            }

            setdateFrom({ month: "", year: "" });
            setselectedMonthFrom("");
            setselectedYearFrom("");       
                        
            setdateTo({ month: "", year: "" });
            setselectedMonthTo("")
            setselectedYearTo("")           


            setMonthList(monthList)
            setYearList(yearList);

            if(Param.periodFrom){  
                setSelectedDateFrom({periodTo : Param.periodFrom},"Month" )
                setSelectedDateFrom({periodTo : Param.periodFrom},"Year")
            }
    
            if(Param.periodTo){

                setSelectedDateTo({periodTo : Param.periodTo},"Month" )
                setSelectedDateTo({periodTo : Param.periodTo},"Year")
            }

        })

    }

    const toggleDrawer = () => { setIsOpen((prevState) => !prevState) }

    const setSelectedDateFrom = (data: any, type: any) => {

        if (type == "Month") {
            selectedMonthFrom = data.periodTo;
            setselectedMonthFrom(data.periodTo);
            setselectedYearFrom(selectedYearFrom);
            dateFrom.month = format(new Date(selectedMonthFrom), 'MMMM dd');
        }
        if (type == "Year") {
            selectedYearFrom = data.periodTo;
            setselectedYearFrom(data.periodTo);
            setselectedMonthFrom(selectedMonthFrom);
            dateFrom.year = format(new Date(selectedYearFrom), 'yyyy');
        }
        setdateFrom(dateFrom)

        console.log(selectedMonthFrom)
        console.log(selectedYearTo)
    }

    const setSelectedDateTo = (data: any, type: any) => {

        console.log(data)
        if (type == "Month") {
            selectedMonthTo = data.periodTo;
            setselectedMonthTo(data.periodTo);
            setselectedYearTo(selectedYearTo);
            dateTo.month = format(new Date(selectedMonthTo), 'MMMM dd');
        }
        if (type == "Year") {
            selectedYearTo = data.periodTo;
            setselectedYearTo(data.periodTo);
            setselectedMonthTo(selectedMonthTo);
            dateTo.year = format(new Date(selectedYearTo), 'yyyy');
        }
        setdateTo(dateTo)

        console.log(selectedMonthFrom)
        console.log(selectedYearTo)
    }

    const returnDate = (e: any) => {
        var dateString = " "
        if (e.month) {
            dateString += e.month;
        }
        if (e.year) {
            dateString += " , " + e.year;
        }

        return dateString;
    }

    const confirmDate = (index: any) => {

        if (index == 1) {
            var obj = { "periodTo": dateTo, "periodFrom": dateFrom }
            Param.onChange(obj);
        }

        Param.closePeriod(true);
        setIsOpen(false);
    }

    return (
        <>
            <button className="btn-success hide" type="button" onClick={() => { setIsOpen(true); }}>
                <i className="icon-calendar"></i>
            </button>

            <Drawer open={Param.open}
                onClose={toggleDrawer}
                direction='right'
                className='react-drawer-left ws-10'>

                <div className="ws-6 box pull-right">
                    <div className="box-header-main">
                        <h2 className="ws-12">Select period(s)</h2>
                    </div>

                    <div className="box-content">

                        <div className="ws-12">
                            <div className="ws-12 mtl">
                                <div className="mbl">

                                    <div className="ws-12 pts">
                                        <div className="ws-12 pm pll mbl">

                                            <ul className="nav nav-tabs m0 p0 pb pull-right">
                                                <li className={accountingPeriod == 1 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(1); selectAccountingPeriod(1) }}>Monthly</li>
                                                <li className={accountingPeriod == 2 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(2); selectAccountingPeriod(2) }}>Quarterly</li>
                                                <li className={accountingPeriod == 3 ? "btn active" : "btn"} onClick={() => { setAccountingPeriod(3); selectAccountingPeriod(3) }}>Bi-Annually</li>
                                            </ul>

                                        </div>

                                        <div className="ws-12">

                                            <div className="ws-6 prx"> 
                                                <div className="ws-12 pm pll">
                                                    <span className="text-bold ws-3 pt"> From :</span>
                                                    <input type="text" className="ws-8 " value={returnDate(dateFrom)} />
                                                </div>
                                            </div>

                                            <div className="ws-6 prx">                                                 
                                                <div className="ws-12 pm pll">
                                                    <span className="text-bold ws-3 pt"> To :</span>
                                                    <input type="text" className="ws-8 " value={returnDate(dateTo)} />
                                                </div>
                                            </div>

                                            <hr/>
                                        </div>
                                        
                                        <div className="ws-6 prx">

                                            <div className="ws-12 pm pll hide">
                                                <span className="text-bold ws-3 pt"> From :</span>
                                                <input type="text" className="ws-8 " value={returnDate(dateFrom)} />
                                            </div>

                                            <div className="ws-6 pll prl">
                                                <div className="text-bold">
                                                    <label className="landing text-center ws-12 pm ml mb bgDark">Year</label>
                                                </div>
                                                <div className="ws-12">
                                                    {yearList.map(post => (
                                                        <a className={post.periodTo == selectedYearFrom ? "ws-12 btn-white text-center ps mb active" : "ws-12 btn-white text-center ps mb"}
                                                            onClick={() => { setSelectedDateFrom(post, "Year") }}>
                                                            {format(new Date(post.periodTo), 'yyyy')}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="ws-6 pll prl">
                                                <div className="text-bold">
                                                    <label className="landing text-center ws-12 pm ml mb bgDark">Month</label>

                                                </div>

                                                <div className="ws-12">
                                                    {monthList.map((post: any) => (
                                                        <a className={post.periodTo == selectedMonthFrom ? "ws-12 btn-white text-center ps mb active" : "ws-12 btn-white text-center ps mb"}
                                                            onClick={() => { setSelectedDateFrom(post, "Month") }}>
                                                            {format(new Date(post.periodTo), 'MMMM dd')}
                                                        </a>
                                                    ))}

                                                </div>
                                            </div>

                                        </div>

                                        <div className="ws-6 border-left plx">

                                            <div className="ws-12 pm pll hide">
                                                <span className="text-bold ws-3 pt"> To :</span>
                                                <input type="text" className="ws-8 " value={returnDate(dateTo)} />
                                            </div>

                                            <div className="ws-6 pll prl">
                                                <div className="text-bold">
                                                    <label className="landing text-center ws-12 pm ml mb bgDark">Year</label>
                                                </div>
                                                <div className="ws-12">
                                                    {yearList.map(post => (
                                                        <a className={post.periodTo == selectedYearTo ? "ws-12 btn-white text-center ps mb active" : "ws-12 btn-white text-center ps mb"}
                                                            onClick={() => { setSelectedDateTo(post, "Year") }}>
                                                            {format(new Date(post.periodTo), 'yyyy')}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="ws-6 pll prl">
                                                <div className="text-bold">
                                                    <label className="landing text-center ws-12 pm ml mb bgDark">Month</label>
                                                </div>
                                                <div className="ws-12">
                                                    {monthList.map((post: any) => (
                                                        <a className={post.periodTo == selectedMonthTo ? "ws-12 btn-white text-center ps mb active" : "ws-12 btn-white text-center ps mb"}
                                                            onClick={() => { setSelectedDateTo(post, "Month") }}>
                                                            {format(new Date(post.periodTo), 'MMMM dd')}
                                                        </a>
                                                    ))}

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="box-footer pull-right pLarge">
                        <button className="btn-success" onClick={() => { confirmDate(1); }}> <i className="icon-calendar4"></i> Select </button>
                        <button className="btn-danger" onClick={() => { confirmDate(2); }}> Close </button>

                    </div>

                </div>

            </Drawer>
        </>
    )

};