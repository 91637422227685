import React, { useEffect, useState } from "react";
import ReportsServices from './ReportsServices'
import Modal from "react-responsive-modal";
import { CommonUtilities } from "commonUtils";


interface ReportMenuItem {
  name: string;
  rptFilePath: string;
  label: string;
  description: string;
}

export default function Reporting_Templates() {

  const [saveReport, setsaveReport] = useState<any[]>([]);
  const [periodCount, setPeriodCount] = useState<any[]>([]);
  const [ReportMenu, setReportMenu] = useState<ReportMenuItem[]>([]);
  const [openFirst, setOpenFirst] = useState(false);
  const [exportReport, setexportReport] = useState<any[]>([]);

  
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState<string>('');

 
  const handleEditClick = (index: number, description: string) => {
    setEditingIndex(index);
    setEditText(description); 
  };

  
  const handleSaveClick = () => {
    if (editingIndex === null) return; 
    const updatedReportMenu = [...ReportMenu];
    updatedReportMenu[editingIndex].description = editText; 
    setReportMenu(updatedReportMenu); 
    setEditingIndex(null); 
    getSaveReport();
  };

  useEffect(() => {
    ReportsServices.ReportMenu()
      .then((res) => {
        console.log(res);
        setReportMenu(res);
      });
  }, []);

  useEffect(() => {
    ReportsServices.periodCount()
      .then((res) => {
        console.log(res);
        setPeriodCount(res);
      });
  }, []);

  const getSaveReport = () => {
    ReportsServices.saveReport()
      .then((res) => {
        console.log(res);
        setsaveReport(res);
      });
  };

  const getReportMenu = () => {
    ReportsServices.ReportMenu()
      .then((res) => {
        console.log(res);
        setReportMenu(res);
      });
  };

  const getExport = () => {
    ReportsServices.exportReport()
      .then((res) => {
        console.log(res);
        setexportReport(res);
      });
  };

  return (
    <div className="ws-12">

      <div className="box-header">
        <h2 className="ws-2">Reporting Arena</h2>

        <div className="form-format ws-10">
          <button className="btn-success pull-right ml" onClick={() => { setOpenFirst(true) }}>
            <i className="icon-LPurple-Setting m0 p0"></i>
          </button>

          <select className="form-control pull-right">
            {
              periodCount.map((post, index) => {
                return (
                  <option value="1" key={index}>
                    {CommonUtilities.dateNameFilter(post.periodFrom)}- {CommonUtilities.dateNameFilter(post.periodTo)}
                  </option>
                )
              })
            }

          </select>

          <label className="ws-1 mt pull-right"> Select Period</label>
        </div>
      </div>

      {
        ReportMenu.map((post, index) => {
          return (
            <div className="ws-4 " key={index}>
              <div className="box-primary ms text-center iconLogo pull-left mll mrl" onClick={getExport}>
                <i className="box icon-reporting1 pbl" />
                <span className="ws-12 mt">{post.label}</span>
              </div>
              <span className="mtl pts text-bold ws-8 "> {post.description}</span>
            </div>
          )
        })
      }

      <Modal open={openFirst} onClose={() => setOpenFirst(false)} center>
        <div className="box-content">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>FilePath</th>
                <th>Label</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {
                ReportMenu.map((post, index) => {
                  return (
                    <tr key={index}>
                      <td>{post.name}</td>
                      <td>{post.rptFilePath}</td>
                      <td>{post.label}</td>
                      <td>
                        {editingIndex === index ? (
                        
                          <div>
                            <input className="ws-8 pull-left"
                              type="text"
                              value={editText}
                              onChange={(e) => setEditText(e.target.value)}
                            />
                            <button className="btn btn-primary btn-sm " onClick={handleSaveClick}>
                              Save
                            </button>
                          </div>
                        ) : (
                         
                          <div>
                            <a
                              className="btn-success btn-sm pull-left"
                              onClick={() => handleEditClick(index, post.description)}
                            >
                              <i className="icon-edit"></i>
                            </a>
                            {post.description}
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </Modal>

     


    </div>
  )
}
