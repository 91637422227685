import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import AccountingRecognition_Template from "./AccountingRecognition_Template";
import CustomReports_Template from "./CustomReports_Template";
import Reporting_Templates from "./Reporting_Templates"
import RoyaltyExpense_Template from "./RoyaltyExpense_Template";
import ReportsServices from './ReportsServices';
import { CommonUtilities }  from '../../commonUtils';

export default function Reports(subEntity: any) {

    const [showtab, setShowTab] = useState(false);
    const [displaytabs , setDisplayTabs] = useState(1);
    const [ReportMenu, setReportMenu] = useState(false);



    const handleCloseModal = () => {        
        setDisplayTabs(1);
    }

    const currentPage = (e: React.SetStateAction<number>) => {
        setDisplayTabs(e);
        return 1;
     }  


    const tabs = [
        {
            id: 1, keys: "reporting",  title: "Reporting"
        },
        {
            id: 2, keys: "Accounting Recognition",title: "Accounting Recognition"
        },
        {
            id: 3,keys: "Royalty Expense", title: "Royalty Expense"
        },
        {
            id: 4, keys: "Custom Reports",title: "Custom Reports"
        },
        
    ]

    const getReportMenu = () => {

        ReportsServices.ReportMenu()
            .then((res) => {
                console.log(res)

                setReportMenu(res);

                 });
                
    }


    
    return (
        <div className="pull-left">
            <div className="box-primary text-center iconLogo pull-left">
                <div className="ws-12" onClick={() => { setShowTab(true); }}>
                    <i className={'box icon-' + subEntity?.subEntity?.subEntityCode} />
                    <span className="m0 pts" >{subEntity?.subEntity?.subEntity} </span>
                </div>
            </div>

            <Modal open={showtab} onClose={() => setShowTab(false)} center >



                <div className="ws-12">

                    <div className="box-header-main">
                        <h2>Reports</h2>
                    </div>

                    <div className="ws-12 pm" style={{backgroundColor : '#f6f8ff ' }}> 

                        <ul className="nav nav-tabs m0 p0 " >
                            {tabs.map((o) => {
                                var temp = '#/Reports/';
                                return <li className ={displaytabs === o.id? "btn active": "btn"} onClick= {() => currentPage(o.id) }> {o.title}</li>
                                    
                            })}                                    
                        </ul>
                            
                    </div>


                    <div className="pLarge header" >
                    {(() => {
                        switch (displaytabs) {
                            case 1 : return  <Reporting_Templates /> 
                            case 2 : return  <AccountingRecognition_Template/>
                            case 3 : return  <RoyaltyExpense_Template/>
                            case 4 : return  <CustomReports_Template/>
                                                                                      
                        }
                    })()}
                    </div>


                </div>

                
            </Modal>
        </div>
    )
}
