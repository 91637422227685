import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import directoryServices from '../directoryServices';


export default function BundleSlider(param: any) {

    const closePage = () => {
        if (saveStatus) {
            param.onAddClose(EditValue);
        }
        if (!saveStatus) {
            param.onAddClose("close");
        }

    }

    let [listImprint, setlistImprint] = useState<any>([]);
    useEffect(() => {
        directoryServices.imprintListing().then((res) => {
            setlistImprint(res)
        })

     //    console.log(param)
        if (param.editData) {
            setEditValue({ ...EditValue, ...param.editData });
        }

    }, [param.open]);

    let [EditValue, setEditValue] = useState<any>([]);
    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;

        console.log(field_name)
        if (field_name == "label") {
            var value = (listImprint.filter((data: any) => (data.companyName == field_value))[0]);
            setEditValue({ ...EditValue, ...{ [field_name]: value } });
        } else {
            setEditValue({ ...EditValue, ...{ [field_name]: field_value } });
        }
    }

    let [imageUpload, setImageUpload] = useState<any>([]);
    const handleFileChange = (e: any) => {

        console.log(e.target.files)

        var fileName = e.target.files[0];
        if (e.target.files) {
            setImageUpload(fileName)
            fileName = URL.createObjectURL(fileName)
            setEditValue({ ...EditValue, ...{ bundleImage: fileName } });
        }

    }

    let [saveStatus, setSaveStatus] = useState(false)
    const SaveAsset = () => {
        console.log(EditValue)
        setSaveStatus(true)
        directoryServices.BundleSave(imageUpload, EditValue)
    }
    return (<>
        <Drawer open={param.open}
            onClose={closePage}
            direction='right'
            className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%" }}>

            <div className="ws-6 box pull-right box">
                <div className='box-header bg-blue'>
                    <h1 className="pull-left ws-10"><i className="fa fa-sticky-note"></i> New Bundle </h1>
                </div>

                <div className='box-content'>
                    <div className="ws-12">
                        <div className="form-format m0 mb">
                            <label className="control-label" >Bundle Code*</label>

                            <div className="label-entries ws-12">
                                <div className="ws-9">
                                    <input key="bundleCode" type="text" className="ws-12" value={EditValue.bundleCode} name="bundleCode" onChange={EditHandler} placeholder="Enter bundleCode" />
                                </div>
                            </div>
                        </div>

                        <div className="ws-11">
                            <div className="form-format m0 mb">
                                <label className="control-label">Imprint*</label>
                                <div className="label-entries ws-12">
                                    <div>
                                        <select key="label" name="bundleImprint" value={EditValue.bundleImprint} onChange={EditHandler}>
                                            <option value="" > Choose an Imprint</option>

                                            {
                                                listImprint?.map(((values: any) => {
                                                    return (
                                                        <option value={values.companyName} label={values.companyName}>{values.companyName}</option>
                                                    )
                                                }))
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Title*</label>
                            <div className="label-entries">
                                <input key="bundleTitle" type="text" name="bundleTitle" value={EditValue.bundleTitle} onChange={EditHandler} placeholder="Enter Title" />
                            </div>
                        </div>

                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 1</label>
                            <div className="label-entries">
                                <input key="bundleDim1Code" type="text" name="bundleDim1Code" value={EditValue.bundleDim1Code} onChange={EditHandler} placeholder="Enter Dim 1" />
                            </div>
                        </div>


                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 2</label>
                            <div className="label-entries">
                                <input key="bundleDim2Code" type="text" name="bundleDim2Code" value={EditValue.bundleDim2Code} onChange={EditHandler} placeholder="Enter Dim 2" />
                            </div>
                        </div>


                        <div className="form-format m0 mb">
                            <label className="control-label">Dim 3</label>
                            <div className="label-entries">
                                <input key="bundleDim3Code" type="text" name="bundleDim3Code" value={EditValue.bundleDim3Code} onChange={EditHandler} placeholder="Enter Dim 3" />
                            </div>
                        </div>

                        <div className="ws-12 mtl">
                            <label className="control-label text-left ws-12 mt text-bold">Bundle Image</label>

                            <div className="ws-12 pm mb">
                                <div className="ws-4">

                                    {(EditValue.bundleImage) && <div className="box shadowCoverPicture shadow " >
                                        <div className="coverPicture" style={{ height: "100%" }}>
                                            <img src={EditValue.bundleImage} className="ws-12" style={{ height: "100%" }} />
                                            <img className="ws-12 hide" />
                                        </div>
                                    </div>}

                                    {!(EditValue.bundleImage) && <table className="coverPictureContainer">
                                        <tbody><tr>
                                            <td style={{ verticalAlign: "middle" }}>
                                                <i className="icon-new-registration"></i>
                                            </td>
                                        </tr>
                                        </tbody></table>}
                                </div>

                                <div className="ws-8 pm">
                                    <label className="btn-primary" style={{ marginTop: "15%" }}>Upload Image
                                        <input type="file" accept="image/*" id="file-upload" className="hide" onChange={handleFileChange} />
                                    </label>
                                    <span className="ws-12 mt">Maximum file size 2mb file types accepted .jpg/ .png/ .bmp (Resolution Minimum 300px X 230px).</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    {/*                     <div className='ws-12 hide'>
                        {<div className="form-format ws-12 mtl box pLarge" >
                            <label className="control-label text-left">Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={ClassificationSearch} placeholder="Type to search for classification" onChange={(e) => setClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => ClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(ClassificationSearch.length > 3) && <div>
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => ClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => ClassificationSave(values.useFreeText)} /> </td>
                                                    <td>{values.useFreeText}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                            </div>

                            {<div className="form-format ws-12 mtl" ng-show="directoryClassifications.length > 0">
                                <label className="control-label text-left w-30 mt">Associated Classification(s):</label>
                                {
                                    associatedclassification?.map((values: any, index: any) => {

                                        return (
                                            <div className=''>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "RGB(255,255,255)" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.categoryName}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteTaging(values.creatorCategoryId)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                                {(ClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                    <div className="alert alert-info" ng-show="!directoryClassifications.length > 0">
                                        No classification available
                                    </div>

                                </div>}
                            </div>}
                        </div>}

                        {<div className="form-format ws-12 mtl box pLarge">
                            <label className="control-label text-left">Sub Classification</label>
                            <div className="label-entries ws-12">
                                <div className="ws-8">
                                    <input type="text" value={SubClassificationSearch} placeholder="Type to search for Sub classification" onChange={(e) => setSubClassificationSearch(e.target.value)} />
                                </div>
                                <div >
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => SubClassificationListing()}>
                                        <i className="icon-search"></i>
                                    </button>
                                </div>
                                {(SubClassificationSearch.length > 3) && <div>
                                    <button className="btn-success btn-xs pl pr pts" onClick={() => SubClassificationSave(false)}>
                                        <i className="icon-save"></i>
                                    </button>
                                </div>}
                            </div>

                            <div className="ws-12">
                                <table className="table ml w-76">
                                    <tbody>
                                        {listSubClassification?.map((values: any) => {
                                            return (
                                                <tr>
                                                    <td width="20%"> <input type="checkbox" onClick={() => SubClassificationSave(values.code)} /> </td>
                                                    <td>{values.code}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>

                                </table>

                            </div>

                            {<div className="form-format ws-12 mtl">
                                <label className="control-label text-left w-30 mt ">Associated Sub Classification(s):</label>

                                {
                                    associatedSubclassification?.map((values: any, index: any) => {

                                        return (
                                            <div>
                                                <a className="btn ms ps" style={{ background: pickerColors[index], color: "#fff" }}>
                                                    <span className="mts mb text-center" style={{ color: "#fff" }}>{values.text}</span>
                                                    <span className="pull-right mtss" onClick={() => deleteSubTaging(values.id)}>
                                                        <i className="icon-close1 text-s ms pls" style={{ color: "#fff" }}></i>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    })


                                }
                                {(SubClassificationtotalRow == 0) && <div className="label-entries ws-8">
                                    <div className="alert alert-info">No Subclassification available</div>

                                </div>}
                            </div>}
                        </div>}
                    </div> */}
                </div>

                <div className="box-footer">

                    <div className="pull-right">
                        <button className="btn-success pull-right" onClick={SaveAsset}> <i className="icon-save"></i> Save </button>
                        <button className="btn-pink pull-right" onClick={closePage}> <i className="icon-close"></i> Close </button>
                    </div>
                </div>

            </div>

        </Drawer>

    </>)
}