import React, { useEffect, useState } from "react";
import ReportsServices from './ReportsServices';
import { CommonUtilities }  from '../../commonUtils';
import Drawer from 'react-modern-drawer';
import Pagination from "../Header/Pagination";
import { Suspense } from "react";
import CommonSearch from "./../../components/commonSearch/CommonSearch";
import globalSearchService from "components/commonSearch/globalSearchServices";



export default function RecordedMusic_Template() {


    const [periodCount, setperiodCount] = useState(false);
    const [showBox4, setShowBox4] = useState(false);
    const [showBox3, setShowBox3] = useState(false);
    const [showBox2, setShowBox2] = useState(false);
    const [showBox, setShowBox] = useState(false);
    const [openFirst, setOpenFirst] = React.useState(false);
    const [openSecond, setOpenSecond] = React.useState(false);
    const [ExistingReferences, setExistingReferences] = useState<any[]>([]);
    const [AddedTransactions, setAddedTransactions] = useState<any[]>([]);
    const [AddedTransactions2Page1, setAddedTransactions2Page1] = useState<any[]>([]);
    const [isRowVisible, setIsRowVisible] = useState(false);
    const [generateArchive, setGenerateArchive] = useState<any[]>([]);

    const toggleRow = (direction: 'up' | 'down') => {
        if (direction === 'up') {
            setIsRowVisible(false); 
        } else {
            setIsRowVisible(true);
        }
    };

    const toggleBox = () => {
        setShowBox(true);
        setShowBox4(true);
        getAddedTransactions();
        getGenerateArchive();
      };

      const toggleBox2 = () => {
        setShowBox2(true);

      };

      const toggleBox3 = () => {
        setShowBox3(true);

      };

    const getExistingReferences = () => {

        ReportsServices.ExistingReferences()
            .then((res) => {
                console.log(res)

                console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                setExistingReferences(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));

                 });

    }

    const getGenerateArchive = () => {

        ReportsServices.generateArchive()
            .then((res) => {
                console.log(res)

                console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                setGenerateArchive(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));

                 });

    }


    useEffect(() => {
        ReportsServices.periodCount()
             .then((res) => {
                 console.log(res)
 
                 
                 setperiodCount(res);
                  
                 })
     }, []);

    const getAddedTransactions = () => {

        ReportsServices.AddedTransactions(Data)
            .then((res) => {
                console.log(res)

                console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                setAddedTransactions(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                settotalRow(res.totalRows);
                 });
                
    }

    
    const getAddedTransactions2 = () => {

        ReportsServices.AddedTransactions2Page1()
            .then((res) => {
                console.log(res)

                console.log(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));
                setAddedTransactions2Page1(CommonUtilities.relativeDataRemapping(res.data, res.headers.original));

                 });
                
    }


    useEffect(() => {

        globalSearchService.lookUpTypeList().then((res) => {
            global.lookUpTypeList = res;
        });

      
    }, )


    const SearchForHandle = (e: any, sts: any) => {
        console.log(e)
        console.log(sts)

        
        
    }



    const [filter, setFilter] = useState("");
    const [filter1, setFilter1] = useState("");
    const [filter2, setFilter2] = useState("");
    
    const transactionsToDisplay2 = filter2
    ? AddedTransactions.filter(post =>post.invoicenum.toLowerCase().includes(filter2.toLowerCase()))
    : AddedTransactions

    const transactionsToDisplay1 = filter1
    ? ExistingReferences.filter(post =>post.manualReference.toLowerCase().includes(filter1.toLowerCase()))
    : ExistingReferences


    const transactionsToDisplay = filter
    ? AddedTransactions2Page1.filter(post => post.invoicenum.toLowerCase().includes(filter.toLowerCase()))
    : AddedTransactions2Page1

  
    let [totalRow, settotalRow] = useState(0);

    const handleNameOnChange = (n :any) => {
        Data.pageNumber = n.pageNumber;
        Data.pageSize = n.pageSize;
        getAddedTransactions();
    }

    let Data = {
        pageNumber: 1,
        pageSize: 10
      };
      

return(

    
    <div className="box-content">

       
        <div className="class" onClick={() => { setOpenFirst(true) ; getExistingReferences(); getAddedTransactions(); getAddedTransactions2(); }}>
                <button  className="btn btn-success pull-right mt">Set Manual Reference</button>

            {showBox4 && (
                <div className="pull-right mll">

                    <div className="form-format ws-3">
                        <label>Week Number</label>
                        <div className="label-entries ws-9">
                            <input type="text"/>
                        </div>
                    </div>

                    <div className="form-format ws-3">
                        <label>Year</label>
                        <div className="label-entries ws-9">
                            <input type="text"/>
                        </div>
                    </div>

                    <div className="form-format ws-4">
                        <label>Manual Reference</label>
                        <div className="label-entries ws-12">
                            <input type="text"/>
                        </div>
                    </div>

                </div>

            )}


            </div>

        <div className="ws-8 pLarge">
            <table>
                <tbody>
                    <tr className="text-left" style={{background: '#ebedf9'}}>
                        <td>
                             <h3 style={{color: '#47529d'}}>Value</h3>
                        </td>
                        <td>
                           <h3 style={{color: '#47529d'}}>Category</h3>
                        </td>
                        <td width={'100%'}>
                            <button
                                className="pull-right btn-primary"
                                onClick={() => toggleRow('up')}
                                style={{ display: isRowVisible ? 'inline-block' : 'none' }}
                            >
                                <i className="icon-circle-up"></i>
                            </button>
                        </td>
                        <td width={'100%'}>
                            <button
                                className="pull-right btn-primary"
                                onClick={() => toggleRow('down')}
                                style={{ display: isRowVisible ? 'none' : 'inline-block'  }}
                            >
                                <i className="icon-circle-down"></i>
                            </button>
                        </td>
                    </tr>

                    {isRowVisible && (
                        <tr className="text-left">
                            <td>
                                <label className="control-label mll">All</label>
                            </td>
                            <td></td>
                            <td className="ps">
                                <div className="verticalMediumWide hover-box-center pull-right">
                                    <ul className="hover-block textboxDropdownList"> 
                                        <li>
                                            <button className="btn btn-primary mbs">
                                                <i className="icon-database"></i>Generate
                                            </button>
                                        </li>
                                        <li className="hover-box">
                                            <button className="btn btn-primary mbs">
                                                <i className="icon-close"></i>Close
                                            </button>
                                        </li>                                   
                                    </ul>

                                    <button className="btn-success">
                                        <i className="icon-tag2"></i>Actions
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )}

                </tbody>

            </table>

          

        </div>

        {showBox4 && (
            <div className="pLarge ws-12 pts pbs">

                <div className="pbs">

                    <div className="ws-6 text-xl info text-bold">
                        <h2 className="text-xl capitalize ">Reportings</h2>
                    </div>

                </div>

                <div className="ws-12 ">
                    <CommonSearch SearchForHandle={SearchForHandle} type="contractGrid" title="Management" width="ws-10 pull-left"/>
                </div>


            </div>

            )}

        <Drawer open={openFirst}
                onClose={() => setOpenFirst(false)}
                direction='right' >
              
              

                <div className="box noBorderRadius noBorder full-height-vh  pull-right ws-4">
                    <div className="box-header-main">
                        <h2>Existing References</h2>
                    </div>
                    
                    <div className="box-content pull-right">
                        <div className="form-format ws-12 mb">
                                <input className="ws-8"  type="text" placeholder="Filter" onChange={(e) => setFilter1(e.target.value)}/>
                                <button className="btn btn-success btn-xs caret lineHeight pull-right" onClick={toggleBox3}><i className="icon-add3"><span className="mts">Add Reference</span></i></button>                           
                                <button className="btn btn-success btn-xs caret lineHeight pull-right"><i className="icon-search"></i></button>
                        </div>
           
                                <div className="ws-12 ">
                                    <table className="table">
                                        <thead>
                                            <th></th>
                                            <th>WeekNumber</th>
                                            <th>YearNumber</th>
                                            <th>ManualReferences</th>
                                        </thead>

                            {
                                transactionsToDisplay1.map((post, index) => {
                                    return (
                                        <tbody>                                                   
                                            <tr>
                                                <td  onClick={toggleBox}><input type="radio" name="radio1" value={post.processReferenceId}/></td>
                                                <td>{post.weekNumber}</td>
                                                <td>{post.yearNumber}</td>
                                                <td>{post.manualReference}</td>
                                                <td><button className="btn-danger btn-xs"><i className="icon-trashcan"></i></button></td>
                                            </tr>
                                            
                                        </tbody>
                            )})}


                                    </table>

                                </div>

                                          
                                                    
                        <div className="box-footer ws-12">
                            <button className="btn-danger pull-right" onClick={() => setOpenFirst(false)}> Close </button>                              
                        </div>       

                    </div>
                </div>

              
                {showBox && ( 

                <div className="box noBorderRadius noBorder full-height-vh  pull-right ws-7 "style={{ position: 'absolute' }}>
            
                    <div className="box-header-main">
                        <h2>Added Transaction</h2>
                    </div>
          
                    <div className="box-content pull-right">
                        <div className="form-format ws-12 mb">                            
                                <input className="ws-9" type="text" placeholder="Filter"  onChange={(e) => setFilter2(e.target.value)}/>
                                <button className="btn btn-success btn-xs caret lineHeight pull-right" onClick={toggleBox2}><i className="icon-add3"><span className="mts">Add Transaction</span></i></button>                           
                                <button className="btn btn-success btn-xs caret lineHeight pull-right" ><i className="icon-search"></i></button>
                        </div>

                                    <div className="ws-12 ">
                                        <table className="table">
                                            <thead>
                                                <th></th>
                                                <th>invoicenum</th>
                                                <th>Headertype</th>
                                                <th>Transactionref</th>
                                                <th>Homevalue</th>
                                                <th>Status</th>
                                                <th>ManualReference</th>
                                                <th>ExtendedStatus</th>
                                                <th>invoiceDate</th>
                                            </thead>

                                {
                                    transactionsToDisplay2.map((post, index) => {
                                        return (
                                            <tbody>

                                                <tr>
                                                    <td><button className="btn-danger btn-xs"><i className="icon-trashcan"></i></button></td>
                                                    <td>{post.invoicenum}</td>
                                                    <td>{post.headertype}</td>
                                                    <td>{post.transactionref}</td>
                                                    <td>{post.homevalue}</td>
                                                    <td>{post.status}</td>
                                                    <td>{post.manualReference}</td>
                                                    <td>{post.extendedStatus}</td>
                                                    <td>{post.invoiceDate}</td>
                                                </tr>
                                                
                                            </tbody>
                                )})}


                                        </table>

                                    </div>

                                          
                                                    
                        <div className="box-footer ws-12">
                            <button className="btn-danger pull-right" onClick={() => setShowBox(false)}> Close </button>                              
                        </div>       

                    </div>

                </div>

                        )}

        {showBox2 && ( 

            <div className="box noBorderRadius noBorder full-height-vh  pull-right ws-7 "style={{ position: 'absolute' }}>

                <div className="box-header-main">
                    <h2>Transaction To Add</h2>
                </div>

                    <div className="box-content pull-right">

                        <div className="form-format ws-12 mb">
                            <div className="label-entries ws-12">
                                <input type="text" placeholder="Filter" onChange={(e) => setFilter(e.target.value)}/>            
                            </div>
                        </div>
                                    <div className="ws-12 ">
                                        <table className="table">
                                            <thead>
                                                <th></th>
                                                <th>invoicenum</th>
                                                <th>Headertype</th>
                                                <th>Transactionref</th>
                                                <th>Homevalue</th>
                                                <th>Status</th>
                                                <th>ManualReference</th>
                                                <th>ExtendedStatus</th>
                                                <th>invoiceDate</th>
                                            </thead>

                                {
                                     transactionsToDisplay.map((post, index) => {
                                        return (
                                            <tbody>

                                                <tr>
                                                    <td  ><input type="checkbox" name="checkbox2"/></td>
                                                    <td>{post.invoicenum}</td>
                                                    <td>{post.headertype}</td>
                                                    <td>{post.transactionref}</td>
                                                    <td>{post.homevalue}</td>
                                                    <td>{post.status}</td>
                                                    <td>{post.manualReference}</td>
                                                    <td>{post.extendedStatus}</td>
                                                    <td>{post.invoiceDate}</td>
                                                </tr>
                                                
                                            </tbody>
                                            
                                )})}
                               
                                        </table>
                                                                                                                                                                         
                                    </div>

                                  
                                                              
                        <div className="box-footer ws-12">
                                {
                                    (() => {
                                        if (totalRow && totalRow > 10) {
                                            return <Pagination onChange={handleNameOnChange} totalRows={totalRow} />
                                        }

                                    })()
                                }                                
                            <button className="btn-danger pull-right" onClick={() => setShowBox2(false)}> Close </button>                              
                        </div>       

                </div>

            </div>

                            )}
                                        
        {showBox3 && ( 

            <div className="box noBorderRadius noBorder full-height-vh  pull-right ws-7 "style={{ position: 'absolute' }}>

                <div className="box-header-main">
                    <h2>Reporting Invoices</h2>
                </div>

                <div className="box-content">

                        <div className="ws-12">
                            <div className="form-format">
                                <label className="control-label" >Manual Reference</label>
                                <div className="label-entries ws-8">
                                    <input type="text"/>
                                </div>
                            </div>

                            <div className="form-format">
                                <label className="control-label">Year</label>
                                <select className="text-uppercase" placeholder="Year Number" id="yearNumber" required name="yearNumber">
                                    <option>Choose Year</option>
                                    <option value={0} label="2014">2014</option>
                                    <option value={1} label="2015">2015</option>
                                    <option value={2} label="2016">2016</option>
                                    <option value={3} label="2017">2017</option>
                                    <option value={4} label="2018">2018</option>
                                    <option value={5} label="2019">2019</option>
                                </select>
                            </div>

                            <div className="form-format">
                                <label className="control-label">Week Number</label>
                                <div className="label-entries ws-8">
                                    <input type="text" className="text-uppercase" required name="weekNumber" id="weekNumber"></input>
                                </div>
                            </div>

                        </div>

                        <div className="box-footer">
                            <button className="btn-success pull-right"><i className="icon-floppy"></i>Save</button>
                            <button className="btn-pink pull-right"><i className="icon-refresh2"></i>Reset</button>
                            <button className="btn-danger pull-right" onClick={() => setShowBox3(false)}>Close</button>
                        </div> 
                </div>

            </div>

        )}
                                    
        </Drawer>

        <Drawer open={openSecond}
                onClose={() => setOpenSecond(false)}
                direction='right' >

                <div className="box noBorderRadius noBorder full-height-vh allowVscroll pull-right ws-6">
                    <div className="box-header-main">
                        <h2 className="pull-left mts">Search reportings</h2>
                        <div className="verticalMediumWide hover-box text-center pull-left mr mls ">

                            <ul className="hover-block box mtll p0 pts pbs"></ul>
                            <div className="btn-info pull-left ml">
                                <span className="pr">Project Tags</span>
                                <i className="icon-plus m0 pull-right pl"></i>
                            </div>

                        </div>

                        <div className="verticalMediumWide hover-box text-center pull-right">
                            <ul className="hover-block textboxDropdownList box pLarge rightReset"> </ul>
                            <button className="btn"><i className="icon-settings m0 p0 text-1"></i></button>
                        </div>                        

                    </div>

                    <div className="box-content">
                        <form name="frmBasket">

                        </form>
                    </div>

                    <button className="btn-danger pull-right" onClick={() => setOpenSecond(false)}> Close </button>  

                </div>  
                                                                      
        </Drawer>

    </div>

)

}