import Dashboard from '../../components/Dashboard/dashboard';
import AuditTrail from '../User Profile/AuditTrail';
import ClientProfile from '../../components/User Profile/Client Profiles/ClientProfile';
import Email from '../User Profile/Email';
import CurrentJobs from '../User Profile/CurrentJobs';
import React, { useState, useEffect } from 'react';
import { CommonUtilities } from '../../commonUtils';
import dashboardService from '../../components/Dashboard/dashboardServices';
import ThemeIndex from '../../components/ThemeCustomization/ThemeIndex';


import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { HexColorPicker } from "react-colorful";
import UserManagementUI from '../User Profile/UserManagement/UserManagementUI';
import PeriodManagement from '../User Profile/PeriodManagement/PeriodManagement';
import SystemListings from '../User Profile/systemListing/systemListingsUI';


export default function Navbar() {

    const [pageNumber, setPageNumber] = useState(0);

    var userAccessMenu = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).userMenu;
    var liveAccessToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).liveOption;

    const SubEntities = () => {
        return (
            <div className='hover-box pl pull-right ws-12'>



                {userAccessMenu.map(function (subEntity: any, indexSubEntities: number) {

                    if (subEntity.subEntityType) {
                        return (
                            CommonUtilities.portalSelection(subEntity)
                        );
                    } else {

                        return (
                            <div className='box mb ws-12'>
                                <div className='box-header'>
                                    {subEntity.entityName}
                                    {indexSubEntities == 0 && <Logout />}
                                </div>
                                <div className='box-content'>
                                    {subEntity.subEntities?.map((userMenu: any) => {

                                        if (userMenu.subEntityRef == 201) {
                                            return (
                                                <a href='apps/systemSettings/#/clientProfile' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-user2"></i>
                                                        <span className="m0 pts ">Client Profile</span>
                                                    </div>
                                                </a>);

                                         /*   return <ClientProfile />*/


                                        } else if (userMenu.subEntityRef == 202) {
                                            return <CurrentJobs />
                                        } else if (userMenu.subEntityRef == 203) {
                                            return ( 
                                            <div className={"box-primary ms text-center iconLogo pull-left  mrl"}>
                                                <i className="box icon-mail" onClick={() => { setopenEmailFeed(true); }}></i>
                                                <span className="m0 pts ">Email Feed</span>
                                            </div>
                                            )
                                        } else if (userMenu.subEntityRef == 204) {
                                            return (
                                                <a href='apps/systemSettings/#/auditTrail' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-line-chart"></i>
                                                        <span className="m0 pts ">Audit Trail</span>
                                                    </div>
                                                </a>);
                                      /*       return <AuditTrail /> */
                                        } else if (userMenu.subEntityRef == 205) {
                                            return (
                                                <a href='apps/userManagement/#/register' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-contacts"></i>
                                                        <span className="m0 pts ">User Management </span>
                                                    </div>
                                                </a>);
/*                                             return ( <UserManagementUI/>); */
                                        } else if (userMenu.subEntityRef == 206) {
                                            return (
                                                <a href='apps/systemSettings/#/periodMGT' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-calendar5"></i>
                                                        <span className="m0 pts ">Period Management </span>
                                                    </div>
                                                </a>);
/*                                             return <PeriodManagement/>; */

                                        } else if (userMenu.subEntityRef == 207) {
                                            return (
                                                <a href='apps/systemSettings/#/controlPanel' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-settings1"></i>
                                                        <span className="m0 pts ">Control Pannel</span>
                                                    </div>
                                                </a>);

                                        } else if (userMenu.subEntityRef == 208) {
                                            return (
                                                <a href='apps/systemListings/#/' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-stack2"></i>
                                                        <span className="m0 pts ">System Listings </span>
                                                    </div>
                                                </a>);
                                            /* return <SystemListings /> */
                                        } else if (userMenu.subEntityRef == 209) {
                                            return (
                                                <a href='../../' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-refresh"></i>
                                                        <span className="m0 pts ">Recycle Pool</span>
                                                    </div>
                                                </a>);
                                        } else if (userMenu.subEntityRef == 210) {
                                            return (
                                                <a href='../../' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-refresh1"></i>
                                                        <span className="m0 pts ">Refresh</span>
                                                    </div>
                                                </a>);
                                        }
                                    })}
                                </div>
                            </div>
                        );


                    }
                })}

                <div className="box-primary text-center iconLogo pull-right hide">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-user2"></i>
                            Profile
                        </div>
                    </a>
                </div>
            </div>

        )
    }

    const LiveAccess = () => {
        if (liveAccessToken) {
            return (
                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-broadcast"></i>
                            Live
                        </div>
                    </a>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    const Logout = () => {
        return (
            <button className='btn-purple pull-right btn-xxs' onClick={CommonUtilities.clearAuthToken}>
                <i className="icon-stand-by"></i>
                <span className='ml mr'>
                    Logout
                </span>
            </button>)
    }


    const changePage = (e: any) => {
        setPageNumber(e);
        //pageNumber = e ;
    }

    const handleRecyclePool = () => {
        dashboardService.recyclePool().then(res => {
            console.log('API response:', res)
        })
            .catch(error => {
                console.log('API CALL ERROR', error);
            });
    }
 
    const   [openEmailFeed , setopenEmailFeed] = useState(false);
    const EmailFeedClose = (e: any) => { 
        setopenEmailFeed(false)
    }

    return (
        <div className='pull-right' >

            <div className="hover-box pl pull-right" >

                <button className='btn-purple pull-right hide'>
                    <i className="icon-menu text-l width-auto pull-right mr btn-xs" onClick={CommonUtilities.clearAuthToken}></i>
                </button>

                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-menu"></i>
                            Menu
                        </div>
                    </a>
                </div>

                <ul className="hover-block rightReset landing ws-6 pull-left mr" ><Dashboard /></ul>

            </div>

            <div className="hover-box pl pull-right" >
                { }
                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-user2"></i>
                            Profile
                        </div>
                    </a>
                </div>

                <ul className="hover-block rightReset landing ws-6 pull-left mr">
                    <SubEntities></SubEntities>
                </ul>
            </div>

            <LiveAccess></LiveAccess>

            <ThemeIndex></ThemeIndex>


                {(() => { 

                    if (openEmailFeed) {
                        return <Email openEmailFeed={openEmailFeed} EmailFeedClose={EmailFeedClose}/>;
                    }

                })()} 
        </div>
    )
}